import React, {Component} from 'react';

class About extends React.Component {
    render() {
        return(
            <div>


        {/* PRELOADER SPINNER
		============================================= */}	
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
		============================================= */}	
        <div id="page" className="page">
          
          {/* PAGE HERO
			============================================= */}	
          <div id="about-page" className="rel crocus_gradient bg_shape_01 page-hero-section division">
            <div className="container">	
              <div className="row">	
                <div className="col-lg-8 offset-lg-2">
                  <div className="hero-txt text-center white-color">
                    {/* Title */}
                    <h2 className="h2-md">İleri Teknolojiyle Desteklenen Yönetim</h2>
                    {/* Text */}	
                    <p className="p-xl">Gelişmiş teknolojik altyapı ile projelerinizi daha verimli yönetin, riskleri minimuma indirin ve başarıyı artırın
                    </p>
                  </div>
                </div>	
              </div>	  {/* End row */}
            </div>	   {/* End container */} 
          </div>	{/* END PAGE HERO */}	

          <section id="reviews-1" className="rel bg_whitesmoke wide-100 reviews-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-60">		
                    {/* Title */}	
                    <h2 className="h2-md">Değerli Müşterilerimizin Yorumları</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* TESTIMONIALS CONTENT */}
              <div className="row">
                <div className="col-md-12">					
                  <div className="owl-carousel owl-theme reviews-1-wrapper">
                    {/* TESTIMONIAL #1 */}
                    <div className="review-1 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Çözüm Odaklı</h5>
                      {/* Testimonial Text */}
                      <div className="review-1-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">İnşaat projelerimizde verimliliği artıran en önemli araç Workfray oldu. Görevlerin konumunu ve stokları kolayca takip edebilmek bize büyük zaman kazandırıyor	   
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Hakan Özcan</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #1 */}
                    {/* TESTIMONIAL #2 */}
                    <div className="review-1 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Problemlere Çözüm</h5>	
                      {/* Testimonial Text */}
                      <div className="review-1-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Workfray’in raporlama sistemi sayesinde proje ilerlemesini net bir şekilde görebiliyoruz. Bu, stratejik kararlar almak için gerçekten harika bir araç
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Ayşe Şahin</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #2 */}
                    {/* TESTIMONIAL #3 */}
                    <div className="review-1 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Mükemmel Çözümler!</h5>
                      {/* Testimonial Text */}
                      <div className="review-1-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Workfray, sahadaki her detayı anlık izlememize olanak tanıyor. Gerçek zamanlı izleme ve müdahale imkanları sayesinde projelerimiz sorunsuz ilerliyor.
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Gizem Doğan</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #3 */}
                    {/* TESTIMONIAL #4 */}
                    <div className="review-1 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-1" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">En İyi Platform</h5>
                      {/* Testimonial Text */}
                      <div className="review-1-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Maliyet takibi özelliğiyle bütçemizi kontrol altında tutabiliyoruz. Workfray’in bu özelliğiyle fazla harcama yapmadan, projeleri daha verimli bir şekilde yönetiyoruz.
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Derya Ateş</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #4 */}
                    {/* TESTIMONIAL #5 */}
                    <div className="review-1 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Harika Saha Çalışması</h5>
                      {/* Testimonial Text */}
                      <div className="review-1-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Workfray’in mobil uyumlu arayüzü, sahada çalışan ekibimiz için büyük bir avantaj. Nerede olursak olalım, projelerimizi yönetmeye devam edebiliyoruz
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Ahmet Sarı </h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #5 */}
                    {/* TESTIMONIAL #6 */}
                
                 
                
                  </div>
                </div>									
              </div>	{/* END TESTIMONIALS CONTENT */} 
            </div>     {/* End container */}
          </section>	{/* END TESTIMONIALS-1 */}
          {/* CONTENT-2
			============================================= */}
          <section id="content-2" className="wide-50 content-section division">
            <div className="container">
              <div className="row d-flex align-items-center">
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6">
                  <div className="img-block left-column pc-20 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                    <img className="img-fluid"
                    
                    src={`${process.env.PUBLIC_URL}/assets/images/img-02.png`}
                    
                    alt="content-image" />
                  </div>
                </div>
                {/* TEXT BLOCK */}	
                <div className="col-md-7 col-lg-6">
                  <div className="txt-block right-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id grey-color">Özelleştirilebilir Çözümler</span>
                    {/* Title */}	
                    <h2 className="h2-md">Problemlerin Çözüm Zamanı</h2>
                    {/* Text */}	
                    <p className="p-lg">İşletmenizin ihtiyaçlarına göre esnek çözümler sunarak iş süreçlerinizi size özel hale getirin.
                    </p>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
              </div>	   {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-2 */}
          {/* BRANDS-2
			============================================= */}
          <section id="brands-2" className="pb-60 brands-section division">
            <div className="container">	
              {/* BRANDS TITLE */}
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="brands-title text-center">
                    <h4 className="h4-md">İşbirliği Yaptığımız Çözüm Ortaklarımız</h4>
                  </div>
                </div>
              </div>
              {/* BRANDS-2 WRAPPER */}
              <div className="brands-2-wrapper">
                <div className="row">
                  <div className="col-md-12">
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" 
                        
                    src={`${process.env.PUBLIC_URL}/assets/images/brand-1.png`}
                        
                        alt="brand-logo" />
                      </a>
                    </div>
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" 
                        
                    src={`${process.env.PUBLIC_URL}/assets/images/brand-2.png`}
                        
                        alt="brand-logo" />
                      </a>
                    </div>
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" 
                        
                    src={`${process.env.PUBLIC_URL}/assets/images/brand-3.png`}
                        
                        alt="brand-logo" />
                      </a>
                    </div>
                  
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" 
                        
                    src={`${process.env.PUBLIC_URL}/assets/images/brand-4.png`}
                        
                        alt="brand-logo" />
                      </a>
                    </div>
                  
                  </div>
                </div>
              </div>  {/* END BRANDS-2 WRAPPER */}
            </div>     {/* End container */}
          </section>	{/* END BRANDS-2 */}
          <div className="divider-wrapper text-center"><div className="section-divider" /></div>

       
        </div>	
     


            </div>
        )
    }
}
export default About 