import React, {Component} from 'react';

class Home_4 extends React.Component {
    render() {
        return(
            <div>



        {/* PRELOADER SPINNER
		============================================= */}	
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
		============================================= */}	
        <div id="page" className="page">
          {/* HEADER
			============================================= */}
         
          {/* HERO-4
			============================================= */}	
          <section id="hero-4" className="hero-section">
            {/* HERO TEXT */}
            <div className="bg_scroll hero-4-txt division">
              <div className="container white-color">		
                <div className="row">
                  <div className="col-lg-10 offset-lg-1 text-center">
                    {/* Title */}
                    <h2 className="h2-sm">Geleceğin Sahası: Yönetin, Takip Edin, Başarın!</h2>	
                    {/* STORE BADGES */}												
                    <div className="stores-badge mb-10">
                      {/* AppStore */}
                      <a href="#" className="store">
                      <img
                          className="appstore"
                          src={`${process.env.PUBLIC_URL}/assets/images/appstore.png`}
                          alt="appstore-badge"
                        />

   </a>
                      <a href="#" className="store">
                      <img
                          className="googleplay"
                          src={`${process.env.PUBLIC_URL}/assets/images/googleplay.png`}
                          alt="googleplay-badge"
                        /> 
   </a> 
               
                    </div>
                    <span className="os-version">Saha yönetimi hiç bu kadar kolay olmamıştı.</span>			
                  </div>							
                </div>	 													
              </div>	 
            </div> 	 
   
            <div className="hero-4-img division">
              <div className="container">		
                <div className="row">	
                  <div className="col-md-12 text-center">															
                  <img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/hero-4-img.png`} alt="hero-image" />
                  </div>	
                </div>  									
              </div>	 
            </div> 	 
          </section> 


          {/* FEATURES-6
			============================================= */}
          <section id="features-6" className="wide-50 features-section division">
            <div className="container">
              {/* FEATURES-6 WRAPPER */}
              <div className="fbox-6-wrapper">
                <div className="row">
                  {/* FEATURE BOX #1 */}
                  <div id="fb-6-1" className="col-md-6 col-lg-4">
                    <div className="fbox-6 pc-15 mb-50 wow fadeInUp" data-wow-delay="0.4s">
                      {/* Icon & Title */}
                      <div className="fbox-6-title ico-40 clearfix">
                        <span className="flaticon-email skyblue-color" />
                        <h5 className="h5-sm">Verimlilik Artışı</h5>
                      </div>
                      {/* Text */}
                      <p className="p-lg">Her an her yerde işlerinizi yönetmenin rahatlığını yaşayın. 
ss                      </p>
                    </div>
                  </div>	
                  {/* FEATURE BOX #2 */}
                  <div id="fb-6-2" className="col-md-6 col-lg-4">
                    <div className="fbox-6 pc-15 mb-50 wow fadeInUp" data-wow-delay="0.6s">
                      {/* Icon & Title */}
                      <div className="fbox-6-title ico-40 clearfix">
                        <span className="flaticon-users skyblue-color" />
                        <h5 className="h5-sm">Saha Yönetimi</h5>
                      </div>
                      {/* Text */}
                      <p className="p-lg">Görev konumlarını belirleyin, anlık olarak izleyin ve güncelleyin.
                      </p>
                    </div>
                  </div>	
                  {/* FEATURE BOX #3 */}
                  <div id="fb-6-3" className="col-md-6 col-lg-4">
                    <div className="fbox-6 pc-15 mb-50 wow fadeInUp" data-wow-delay="0.8s">
                      {/* Icon & Title */}
                      <div className="fbox-6-title ico-40 clearfix">
                        <span className="flaticon-alarm skyblue-color" />
                        <h5 className="h5-sm">Görev Yönetimi</h5>
                      </div>
                      {/* Text */}
                      <p className="p-lg">Her görevi tek bir platformdan yönetin.
                      </p>
                    </div>
                  </div>	
            
               
               
                </div>  {/* End row */}	
              </div>	{/* END FEATURES-6 WRAPPER */}
            </div>	   {/* End container */}		
          </section>	{/* END FEATURES-6 */}
          {/* CONTENT-12
			============================================= */}
          <section id="content-12" className="mb-60 content-section division">
            <div className="container">
              <div className="lavender_gradient content-12-wrapper">
                <div className="row d-flex align-items-center">
                  <div className="col-lg-5">
                    <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      <h2 className="h2-xs">Maksimum Optimizasyon & Mükemmel Verimlilik</h2>
                      <p className="p-lg">Workfray ile tüm süreçlerinizi optimize edin, her aşamada maksimum verimliliğe ulaşın. Zaman ve kaynak yönetiminden görev dağılımına kadar her adımı mükemmel şekilde planlayın ve yönetin. Verimliliği artıran akıllı çözümlerle projelerinizi en üst düzeye taşıyın
                      </p>
                   
                    </div>
                  </div>	
                  <div className="col-lg-7">
                    <div className="content-12-img right-column wow fadeInLeft" data-wow-delay="0.6s">
                      <img className="img-fluid"      src={`${process.env.PUBLIC_URL}/assets/images/img-12.png`} alt="content-image" />
                    </div>
                  </div>
                </div>
              </div>    {/* End row */}
            </div>	   {/* End container */}	
          </section>	{/* END CONTENT-12 */}
          {/* CONTENT-3
			============================================= */}
     
          {/* FEATURES-4
			============================================= */}
          <section id="features-4" className="pb-100 features-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Saha Yönetiminde Kontrol Sizde!</h2>	
                    {/* Text */}	
                    <p className="p-xl">Karmaşık problemlere pratik çözümler üretiyoruz.
                    </p>
                  </div>	
                </div>
              </div>
              {/* FEATURES-4 WRAPPER */}	
              <div className="fbox-4-wrapper">
                <div className="row">
                  {/* FEATURE BOX #1 */}
                  <div id="fb-4-1" className="col-md-6">
                    <div className="fbox-4 bg_whitesmoke mb-30 wow fadeInUp" data-wow-delay="0.4s">
                      <div className="row d-flex align-items-center m-row">
                        {/* TEXT */}
                        <div className="col-lg-8 m-bottom">
                          <div className="fbox-4-txt">
                            {/* Title */}
                            <h5 className="h5-sm">Departman Yönetimi</h5>
                            {/* Text */}
                            <p className="p-md">Her departmanın faaliyetlerini göz önünde tutun, sorumlulukları anında izleyin.
                            </p>
                          </div>
                        </div>
                        {/* ICON */}
                        <div className="col-lg-4 m-top">
                          <div className="fbox-4-ico ico-100 stateblue-color text-center">
                            <span className="flaticon-call" />
                          </div>
                        </div>
                      </div>	{/* End row */}
                    </div>  {/* End fbox-4 */}
                  </div>	{/* END FEATURE BOX #1 */}
                  {/* FEATURE BOX #2 */}
                  <div id="fb-4-2" className="col-md-6">
                    <div className="fbox-4 bg_whitesmoke mb-30 wow fadeInUp" data-wow-delay="0.6s">
                      <div className="row d-flex align-items-center m-row">
                        {/* TEXT */}
                        <div className="col-lg-8 m-bottom">
                          <div className="fbox-4-txt">
                            {/* Title */}
                            <h5 className="h5-sm">Raporlama & Analiz</h5>
                            {/* Text */}
                            <p className="p-md">Tüm görev ve projelerinizin analizini çıkarın, stratejilerinizi güçlendirin
                            </p>
                          </div>
                        </div>
                        {/* ICON */}
                        <div className="col-lg-4 m-top">
                          <div className="fbox-4-ico ico-100 stateblue-color text-center">
                            <span className="flaticon-tools" />
                          </div>
                        </div>
                      </div>	{/* End row */}
                    </div>  {/* End fbox-4 */}
                  </div>	{/* END FEATURE BOX #2 */}
                  {/* FEATURE BOX #3 */}
                  <div id="fb-4-3" className="col-md-6">
                    <div className="fbox-4 bg_whitesmoke wow fadeInUp" data-wow-delay="0.8s">
                      <div className="row d-flex align-items-center m-row">
                        {/* TEXT */}
                        <div className="col-lg-8 m-bottom">
                          <div className="fbox-4-txt">
                            {/* Title */}
                            <h5 className="h5-sm">Çoklu Proje Yönetimi</h5>
                            {/* Text */}
                            <p className="p-md">Tek bir platformda birden fazla projeyi yönetin, tüm işlerinizi kolayca takip edin.
                            </p>
                          </div>
                        </div>
                        {/* ICON */}
                        <div className="col-lg-4 m-top">
                          <div className="fbox-4-ico ico-100 stateblue-color text-center">
                            <span className="flaticon-smartphone-8" />
                          </div>
                        </div>
                      </div>	{/* End row */}
                    </div>  {/* End fbox-4 */}
                  </div>	{/* END FEATURE BOX #3 */}
                  {/* FEATURE BOX #4 */}
                  <div id="fb-4-4" className="col-md-6">
                    <div className="fbox-4 bg_whitesmoke wow fadeInUp" data-wow-delay="1s">
                      <div className="row d-flex align-items-center m-row">
                        {/* TEXT */}
                        <div className="col-lg-8 m-bottom">
                          <div className="fbox-4-txt">
                            {/* Title */}
                            <h5 className="h5-sm">Merkezi Yönetim</h5>
                            {/* Text */}
                            <p className="p-md">Tek bir platformda birden fazla projeyi yönetin, tüm işlerinizi kolayca takip edin.
                            </p>
                          </div>
                        </div>
                        {/* ICON */}
                        <div className="col-lg-4 m-top">
                          <div className="fbox-4-ico ico-100 stateblue-color text-center">
                            <span className="flaticon-phone-1" />
                          </div>
                        </div>
                      </div>	{/* End row */}
                    </div>  {/* End fbox-4 */}
                  </div>	{/* END FEATURE BOX #4 */}
                </div>  {/* End row */}	
              </div>	{/* END FEATURES-4 WRAPPER */}	
            </div>	   {/* End container */}		
          </section>	{/* END FEATURES-4 */}
          {/* CONTENT-13
			============================================= */}
          <section id="content-13" className="rel crocus_gradient bg_shape_02 wide-60 content-section division">
            <div className="container white-color">
              <div className="row d-flex align-items-center">
                {/* TEXT BLOCK */}		
                <div className="col-md-6 col-xl-6">
                  <div className="txt-block left-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id">Çözüm odaklı</span>
                    {/* Title */}	
                    <h2 className="h2-md">Problemler</h2>
                    {/* List */}	
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">Saha yönetiminde yaşanan karmaşıklıklar ve problemler
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Saha yönetiminde yaşanan kayıp malzemeler ve işveren ve işçiler için anlık haberleşme sorunu</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Çalışanların herşeyi pratik bir şekilde işverenlerine yaptıklarını sunabilme problemi
                        </p>
                      </li>
                    </ul>
                    {/* Button */}	
                    <a href="#content-11" className="btn btn-tra-white skyblue-hover">Çözüm Zamanı</a>
                  </div>	
                </div>	{/* END TEXT BLOCK */}		
                {/* IMAGE BLOCK */}	
                <div className="col-md-6 col-xl-6">
                  <div className="content-13-img wow fadeInRight" data-wow-delay="0.6s">
                    <img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/tablet.png`}alt="content-image" />
                  </div>	
                </div>
              </div>	  {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-13 */}
          {/* CONTENT-2
			============================================= */}
      
          <section id="content-11" className="pb-100 content-section division">
            <div className="container">
            
           
          
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-11 */}
          {/* TESTIMONIALS-4
			============================================= */}
          <section id="reviews-4" className="bg_aliceblue wide-60 reviews-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-60">		
                    {/* Title */}	
                    <h2 className="h2-md">Değerli Müşteri Yorumlarımız</h2>	
                    {/* Text */}	
                    <p className="p-xl">Yorumlarınız ve geri bildirimleriniz bizim için çok değerlidir. Workfray ekibi olarak her zaman yanınızdayız.
                    </p>
                  </div>	
                </div>
              </div>
              {/* TESTIMONIALS-4 WRAPPER */}
              <div className="reviews-4-wrapper">
                <div className="row">
                  {/* TESTIMONIALS LEFT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #1 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="0.4s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Enerji sektöründe işlerimizi hızlandırmak için Workfray'i kullanmaya başladık. Stok yönetimi ve görev konumu takibi bize gerçekten büyük bir avantaj sağladı. Artık hiçbir detayı kaçırmıyoruz. 			   
                          </p>
                        </div>	
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">Merve Kaya</h5>
                          <p>USA</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #1 */}
                      {/* TESTIMONIAL #2 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Birden fazla projeyi aynı anda yönetmek Workfray ile çok daha basit hale geldi. Görev detaylarına hakim olmak ve canlı bağlantılarla sahadaki durumları kontrol edebilmek işleri hızlandırıyor
                          </p>
                        </div>
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* App Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">Elif Çelik</h5>
                          <p>İstanbul</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #2 */}
                    </div>
                  </div>	{/* END TESTIMONIALS LEFT COLUMN */}
                  {/* TESTIMONIALS RIGHT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #3 */}
                      <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Workfray’in sunduğu görev yönetim arayüzü inanılmaz kullanıcı dostu. Hem stok yönetimi hem de görev takibini bu kadar hızlı ve verimli yapabileceğimiz bir platforma ihtiyacımız vardı.
                          </p>
                        </div>	
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* App Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">Burak Aksoy</h5>
                          <p>İzmir</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #3 */}
                    </div>
                  </div>	{/* END TESTIMONIALS RIGHT COLUMN */}
                </div>  {/* End row */}
              </div>	{/* END TESTIMONIALS-4 WRAPPER */}
              {/* DOWNLOAD BUTTON */}		
              <div className="row">
                <div className="col-md-12">
                  <div className="reviews-download-btn wow fadeInUp" data-wow-delay="1s">
                    {/* STORE BADGES */}												
                    <div className="stores-badge mb-5">
                      {/* AppStore */}
                      <a href="#" className="store">
                      <img className="img-fluid" src={`${process.env.PUBLIC_URL}/assets/images/appstore.png`} alt="appstore-badge" />

                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                      <img
                          className="googleplay"
                          src={`${process.env.PUBLIC_URL}/assets/images/googleplay.png`}
                          alt="googleplay-badge"
                        />    </a> 
            
                    </div>	
                    <span className="os-version">Saha yönetimi hiç bu kadar kolay olmamıştı</span>
                  </div>
                </div>
              </div>	
            </div>    
          </section>	{/* END TESTIMONIALS-4 */}
          {/* CONTENT-1
			============================================= */}
       <br></br>
       <br></br>

          {/* PRICING-2
			============================================= */}
          <section id="pricing-2" className="pb-100 pricing-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-12 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Fiyatlandırmalarımız</h2>	
                    {/* Text */}	
                    <p className="p-xl">İhtiyaçlarınıza göre esnek ve adil fiyatlandırma seçenekleri. Workfray ile her bütçeye uygun çözümler sunuyoruz.
                    </p>
                  </div>	
                </div>
              </div>
              {/* PRICING TABLES */}
              <div className="row text-center">
                {/* FREE PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.4s">  
                    {/* Plan Price  */}
                    <div className="pricing-plan bg_white">
                      <h5 className="h5-md">1-10 Çalışan</h5>                  
                      <sup className="dark-color">₺</sup>               
                      <span className="dark-color">2.000</span>
                      <p className="dark-color">Aylık</p>
                      <p className="p-sm">7 Günlük Ücretsiz Deneme Süresi</p>     
                      <a href="#" className="btn btn-tra-grey skyblue-hover mb-15">Planı Seç</a>
                      {/* Pricing Trial Link */}  
                    </div>  
                    {/* Plan Description */}
                    <div className="pricing-description bg_white">
                      <ul className="features">
                        <li><p className="p-lg">Görev Takibi</p></li>
                        <li><p className="p-lg">Stok Takibi</p></li>
                        <li><p className="p-lg">Görev Konum Takibi</p></li>
                        <li><p className="p-lg">Kişisel Asistan</p></li>              
                        <li><p className="p-lg">Analizler</p></li> 
                        <li><p className="p-lg">Yönetici & Çalışan Uygulaması</p></li>

                      </ul>
                    </div>
                  </div>
                </div>  {/* END PFREE PLAN */}
                {/* MONTHLY PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.6s">  
                    {/* Plan Price  */}
                    <div className="pricing-plan bg-white">
                      <h5 className="h5-md">10-30 Çalışan</h5>  
                      <sup className="dark-color">₺</sup>                 
                      <span className="dark-color">6.000</span>
                      <p className="dark-color">Aylık</p>
                      <p className="p-sm">7 Günlük Ücretsiz Deneme Süresi</p>
                      {/* Pricing Table Button */}
                      <a href="#" className="btn btn-tra-grey skyblue-hover mb-15">Planı Seç</a>
                      {/* Pricing Trial Link */}  
                    </div>  
                    {/* Plan Description */}
                    <div className="pricing-description bg-white">
                      <ul className="features">
                      <li><p className="p-lg">Görev Takibi</p></li>
                        <li><p className="p-lg">Stok Takibi</p></li>
                        <li><p className="p-lg">Görev Konum Takibi</p></li>
                        <li><p className="p-lg">Kişisel Asistan</p></li>              
                        <li><p className="p-lg">Analizler</p></li> 
                        <li><p className="p-lg">Yönetici & Çalışan Uygulaması</p></li>

                      </ul>
                    </div>
                  </div>
                </div>  {/* END MONTHLY PLAN */}
                {/* ANNUAL PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.8s">  
                    {/* Plan Price  */}
                    <div className="pricing-plan highlight crocus_gradient white-color">
                      <h5 className="h5-md">30-50 Çalışan</h5> 
                      <sup>₺</sup>              
                      <span>12.000</span>
                      <p>Aylık</p>
                      <p className="p-sm">7 Günlük Ücretsiz Deneme Süresi</p>
                      {/* Pricing Table Button */}
                      <a href="#" className="btn btn-tra-white white-hover mb-15">Planı Seç</a>
                      {/* Pricing Trial Link */}  
                    </div>  
                    {/* Plan Description */}
                    <div className="pricing-description bg-white">
                      <ul className="features">
                      <li><p className="p-lg">Görev Takibi</p></li>
                        <li><p className="p-lg">Stok Takibi</p></li>
                        <li><p className="p-lg">Görev Konum Takibi</p></li>
                        <li><p className="p-lg">Kişisel Asistan</p></li>              
                        <li><p className="p-lg">Analizler</p></li> 
                        <li><p className="p-lg">Yönetici & Çalışan Uygulaması</p></li>

                      </ul>
                    </div>
                  </div>
                </div>  {/* END ANNUAL PLAN */}
                
              </div>  {/* END PRICING TABLES */}
              <div className="row">
                <div className="col-md-12">
                  <div className="pricing-2-download-btn mt-20 text-center wow fadeInUp" data-wow-delay="0.8s">
                    <a href="pricing.html" className="btn btn-md btn-tra-grey skyblue-hover mb-10">7 Günlük Ücretsiz Deneme Süresi</a>
                    <span className="os-version">Şimdi Başlama Zamanı</span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}	
            </div>	   {/* End container */}
          </section>	{/* END PRICING-2 */}
          {/* BRANDS-2
			============================================= */}
          <section id="brands-2" className="bg_whitesmoke wide-70 brands-section division">
            <div className="container">	
              {/* BRANDS TITLE */}
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="brands-title text-center">
                    <h4 className="h4-md">Birlikte Çalıştığımız Referanslarımız</h4>
                  </div>
                </div>
              </div>
              {/* BRANDS-2 WRAPPER */}
              <div className="brands-2-wrapper">
                <div className="row">
                  <div className="col-md-12">
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid"                       src={`${process.env.PUBLIC_URL}/assets/images/brand-1.png`}
 alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid"                       src={`${process.env.PUBLIC_URL}/assets/images/brand-2.png`}
 alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid"                      src={`${process.env.PUBLIC_URL}/assets/images/brand-3.png`}
 alt="brand-logo" />
                      </a>
                    </div>

                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid"                      src={`${process.env.PUBLIC_URL}/assets/images/brand-4.png`}
 alt="brand-logo" />
                      </a>
                    </div>
                
                
               
                 
                 
                  </div>
                </div>
              </div>  {/* END BRANDS-2 WRAPPER */}
            </div>     {/* End container */}
          </section>	{/* END BRANDS-2 */}
          {/* FAQs-2
			============================================= */}
        
          {/* DOWNLOAD-1
			============================================= */}
          <section id="download-1" className="rel violet_gradient bg_shape_02 pt-100 download-section division">
            <div className="container white-color">
              <div className="row d-flex align-items-center m-row">
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6 m-bottom">
                  <div className="img-block right-column pc-25 wow fadeInUp" data-wow-delay="0.6s">

                    
                    <img className="img-fluid"     src={`${process.env.PUBLIC_URL}/assets/images/img-15.png`} alt="content-image" />
                  </div>
                </div>
                {/* TEXT BLOCK */}	
                <div className="col-md-7 col-lg-6 m-top">
                  <div className="txt-block left-column pc-20 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Title */}
                    <h2 className="h2-md">Yönetimden Sahaya &amp;   Tam Bağlantı!</h2>
                    {/* Text */}
                    <p className="p-xl">Workfray ile her projede şeffaflık ve tam yönetim. Görev konumlarını belirleyin, güncelleyin ve sahadaki tüm detaylara hâkim olun.</p>
                    {/* STORE BADGES */}
                    <div className="stores-badge">
                      {/* AppStore */}
                      <a href="#" className="store">
                      <img
                          className="appstore"
                          src={`${process.env.PUBLIC_URL}/assets/images/appstore.png`}
                          alt="appstore-badge"
                        />
     </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                      <img
                          className="googleplay"
                          src={`${process.env.PUBLIC_URL}/assets/images/googleplay.png`}
                          alt="googleplay-badge"
                        /> 
  </a>
                    </div>	
                    {/* Rating */}
                    <div className="txt-block-rating">
                      <div className="stars-rating">
                  Tüm Versiyon
                        <span className="flaticon-star ml-5" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />	
                        <p className="txt-rating">19.000+ Kullanıcı ve 30.000 Şirket(App Store &amp; Google Play)</p>
                      </div>			
                    </div>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
              </div>    {/* End row */}
            </div>	   {/* End container */}	
          </section>	{/* END DOWNLOAD-1 */}
          
        </div>	{/* END PAGE CONTENT */}
      

        
            </div>
        )
    }
}
export default Home_4 