import React, {Component} from 'react';

class Home_9 extends React.Component {
    render() {
        return(
            <div>


        {/* PRELOADER SPINNER
		============================================= */}	
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
		============================================= */}	
        <div id="page" className="page">
          {/* HEADER
			============================================= */}
          
          {/* HERO-9
			============================================= */}	
          <section id="hero-9" className="rel bg_shape_01 hero-section division">
            <div className="container">	
              <div className="row d-flex align-items-center">
                {/* HERO IMAGE */}
                <div className="col-md-6 col-lg-7">	
                  <div className="hero-9-img text-center wow fadeInRight" data-wow-delay="0.6s">			
                    <img className="img-fluid" src="assets/images/hero-9-img.png" alt="hero-image" />
                  </div>
                </div>
                {/* HERO TEXT */}					
                <div className="col-md-6 col-lg-5">
                  <div className="hero-9-txt wow fadeInLeft" data-wow-delay="0.4s">
                    <h4 className="h4-xs">NordEx®</h4>		
                    {/* Title */}
                    <h2 className="h2-lg">Showcase Your App in Easy Way</h2>
                    {/* Text */}
                    <p className="p-lg dark-color">Feugiat primis ligula risus an auctor egestas augue viverra mauri at tortor iaculis
                      magna feugiat mauris ipsum undo viverra tortor and placerat gravida
                    </p> 
                    {/* HERO REQUEST FORM */}
                    <form name="requestform" className="request-form">
                      {/* Form Inputs */}	
                      <div className="input-group">
                        <input type="email" name="email" className="form-control email" placeholder="Your email address" required />
                        <span className="input-group-btn form-btn">
                          <button type="submit" className="btn btn-md btn-skyblue black-hover submit">Get Started</button>
                        </span>										
                      </div>	
                      {/* Form Message */}
                      <div className="request-form-msg"><span className="loading" /></div>	
                    </form>	
                    {/* OS Prerequisite */}
                    <span className="os-version dark-color">Try NordEx free for 14 days. Cancel anytime.</span>	
                  </div>  
                </div>	{/* END HERO TEXT */}
              </div>	  {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END HERO-9 */}
          {/* CONTENT-3
			============================================= */}
          <section id="content-3" className="wide-60 content-section division">
            <div className="container">
              {/* CONTENT BOX-1 */}
              <div id="cb-1-1" className="cbox-1 pb-25">
                <div className="row d-flex align-items-center m-row">
                  {/* TEXT BLOCK */}	
                  <div className="col-md-7 col-lg-6 m-bottom">
                    <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      {/* Section ID */}	
                      <span className="section-id grey-color">Beauty of Simplicity</span>
                      {/* Title */}	
                      <h2 className="h2-md">Beautiful, award-winning design</h2>
                      {/* Text */}	
                      <p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue 
                        luctus magna dolor luctus at egestas sapien vitae. Nemo egestas volute and turpis dolores aliquam quaerat 
                        sodales a sapien
                      </p>
                      {/* Text */}	
                      <p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue 
                        luctus magna dolor luctus and egestas sapien egestas vitae nemo volute
                      </p>
                    </div>
                  </div>	{/* END TEXT BLOCK */}	
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6 m-top">
                    <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img-03.png" alt="content-image" />
                    </div>
                  </div>
                </div>  {/* End row */}	
              </div>	{/* END CONTENT BOX-1 */}
              {/* CONTENT BOX-2 */}
              <div id="cb-1-2" className="cbox-1">
                <div className="row d-flex align-items-center">
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6">
                    <div className="img-block left-column pc-25 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img-02.png" alt="content-image" />
                    </div>
                  </div>
                  {/* TEXT BLOCK */}	
                  <div className="col-md-7 col-lg-6">
                    <div className="txt-block right-column pc-30 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      {/* Section ID */}	
                      <span className="section-id grey-color">Perfect Integration</span>
                      {/* Title */}	
                      <h2 className="h2-md">Work smarter with powerful features</h2>
                      {/* List */}	
                      <ul className="simple-list">
                        <li className="list-item">
                          <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium 
                            ligula rutrum tempor sapien
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo 
                            pretium a purus mauris
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>	{/* END TEXT BLOCK */}	
                </div>	  {/* End row */}	
              </div>	{/* END CONTENT BOX-2 */}
            </div>     {/* End container */}
          </section>	{/* END CONTENT-3 */}	
          {/* FEATURES-13
			============================================= */}
          <section id="features-13" className="rel aliceblue_shape mt-inverse-40 features-section division">
            <div className="container">
              <div className="row d-flex align-items-center">
                {/* TEXT BLOCK */}	
                <div className="col-md-10 col-lg-5">
                  <div className="txt-block pc-15 wow fadeInUp" data-wow-delay="0.4s">
                    {/* Section ID */}	
                    <span className="section-id grey-color">Flexible Features</span>
                    {/* Title */}	
                    <h2 className="h2-md">Simple to use, easy to love</h2>
                    {/* Text */}
                    <p className="p-lg">Semper lacus cursus porta, feugiat primis and donec ultrice ligula tempus an auctor ipsum nihil mauris
                      lectus enim ipsum sagittis congue		   
                    </p>
                    {/* Text */}
                    <p className="p-lg">Gravida porta velna vitae auctor congue magna nihil impedit ligula risus mauris donec ligula</p>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
                {/* FEATURES-13 WRAPPER */}
                <div className="col-lg-7">
                  <div className="fbox-13-wrapper pl-35">	
                    <div className="row">
                      <div className="col-md-6">
                        {/* FEATURE BOX #1 */}
                        <div id="fb-13-1" className="fbox-13 mt-50 mb-30 wow fadeInUp" data-wow-delay="0.4s">
                          {/* Icon */}
                          <div className="fbox-13-ico ico-75"><span className="flaticon-ads" /></div>
                          {/* Title */}
                          <h5 className="h5-sm">Friendly Interface</h5>
                          {/* Text */}
                          <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor ultrice ociis</p>
                        </div>
                        {/* FEATURE BOX #2 */}
                        <div id="fb-13-2" className="fbox-13 wow fadeInUp" data-wow-delay="0.6s">
                          {/* Icon */}
                          <div className="fbox-13-ico ico-75"><span className="flaticon-switch" /></div>
                          {/* Title */}
                          <h5 className="h5-sm">Powerful Options</h5>
                          {/* Text */}
                          <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor ultrice ociis</p>
                        </div>
                      </div>
                      <div className="col-md-6">
                        {/* FEATURE BOX #3 */}
                        <div id="fb-13-3" className="fbox-13 mb-30 wow fadeInUp" data-wow-delay="0.4s">
                          {/* Icon */}
                          <div className="fbox-13-ico ico-75"><span className="flaticon-pantone" /></div>
                          {/* Title */}
                          <h5 className="h5-sm">Customization</h5>
                          {/* Text */}
                          <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor ultrice ociis</p>
                        </div>
                        {/* FEATURE BOX #4 */}
                        <div id="fb-13-4" className="fbox-13 wow fadeInUp" data-wow-delay="0.6s">
                          {/* Icon */}
                          <div className="fbox-13-ico ico-75"><span className="flaticon-user-1" /></div>
                          {/* Title */}
                          <h5 className="h5-sm">Multiple Accounts</h5>
                          {/* Text */}
                          <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor ultrice ociis</p>
                        </div>
                      </div>
                    </div>
                  </div>	{/* End row */}
                </div>	{/* END FEATURES-13 WRAPPER */}
              </div>	 {/* End row */}	
            </div>	  {/* End container */}
          </section>	{/* END FEATURES-13 */}
          {/* CONTENT-2
			============================================= */}
          <section id="content-2" className="wide-50 content-section division">
            <div className="container">
              <div className="row d-flex align-items-center">
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6">
                  <div className="img-block left-column pc-20 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/img-08.png" alt="content-image" />
                  </div>
                </div>
                {/* TEXT BLOCK */}	
                <div className="col-md-7 col-lg-6">
                  <div className="txt-block right-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id grey-color">Unlimited Access</span>
                    {/* Title */}	
                    <h2 className="h2-md">More productivity with less effort</h2>
                    {/* Text */}
                    <p className="p-lg">Gravida porta velna vitae auctor congue at magna nihil impedit ligula risus mauris donec 
                      a ligula risus 
                    </p>
                    {/* Text */}
                    <p className="p-lg">Aliqum mullam blandit and tempor sapien donec lipsum gravida porta. Velna vitae auctor 
                      congue magna impedit ligula risus. Mauris donec ligula an impedit magnis
                    </p>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
              </div>	   {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-2 */}
          {/* FEATURES-6
			============================================= */}
          <section id="features-6" className="pb-50 features-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Ready to Try NordEx?</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* FEATURES-6 WRAPPER */}
              <div className="fbox-6-wrapper">
                <div className="row">
                  {/* FEATURE BOX #1 */}
                  <div id="fb-6-1" className="col-md-6 col-lg-4">
                    <div className="fbox-6 pc-15 mb-50 wow fadeInUp" data-wow-delay="0.4s">
                      {/* Icon & Title */}
                      <div className="fbox-6-title ico-40 grey-color clearfix">
                        <span className="flaticon-call" />
                        <h5 className="h5-sm">Quick Access</h5>
                      </div>
                      {/* Text */}
                      <p className="p-lg">Nullam tempor sapien gravida donec blandit ipsum porta justo integer and velna vitae 
                        auctor integer congue
                      </p>
                    </div>
                  </div>	
                  {/* FEATURE BOX #2 */}
                  <div id="fb-6-2" className="col-md-6 col-lg-4">
                    <div className="fbox-6 pc-15 mb-50 wow fadeInUp" data-wow-delay="0.6s">
                      {/* Icon & Title */}
                      <div className="fbox-6-title ico-40 grey-color clearfix">
                        <span className="flaticon-chat-4" />
                        <h5 className="h5-sm">Smart Notifications</h5>
                      </div>
                      {/* Text */}
                      <p className="p-lg">Nullam tempor sapien gravida donec blandit ipsum porta justo integer and velna vitae 
                        auctor integer congue
                      </p>
                    </div>
                  </div>	
                  {/* FEATURE BOX #3 */}
                  <div id="fb-6-3" className="col-md-6 col-lg-4">
                    <div className="fbox-6 pc-15 mb-50 wow fadeInUp" data-wow-delay="0.8s">
                      {/* Icon & Title */}
                      <div className="fbox-6-title ico-40 grey-color clearfix">
                        <span className="flaticon-fingerprint" />
                        <h5 className="h5-sm">Online Verification</h5>
                      </div>
                      {/* Text */}
                      <p className="p-lg">Nullam tempor sapien gravida donec blandit ipsum porta justo integer and velna vitae 
                        auctor integer congue
                      </p>
                    </div>
                  </div>	
                  {/* FEATURE BOX #4 */}
                  <div id="fb-6-4" className="col-md-6 col-lg-4">
                    <div className="fbox-6 pc-15 mb-50 wow fadeInUp" data-wow-delay="1s">
                      {/* Icon & Title */}
                      <div className="fbox-6-title ico-40 grey-color clearfix">
                        <span className="flaticon-image" />
                        <h5 className="h5-sm">Share Files &amp; Media</h5>
                      </div>
                      {/* Text */}
                      <p className="p-lg">Nullam tempor sapien gravida donec blandit ipsum porta justo integer and velna vitae 
                        auctor integer congue
                      </p>
                    </div>
                  </div>	
                  {/* FEATURE BOX #5 */}
                  <div id="fb-6-5" className="col-md-6 col-lg-4">
                    <div className="fbox-6 pc-15 mb-50 wow fadeInUp" data-wow-delay="1.2s">
                      {/* Icon & Title */}
                      <div className="fbox-6-title ico-40 grey-color clearfix">
                        <span className="flaticon-harddisk-1" />
                        <h5 className="h5-sm">Storage &amp; Backup</h5>
                      </div>
                      {/* Text */}
                      <p className="p-lg">Nullam tempor sapien gravida donec blandit ipsum porta justo integer and velna vitae 
                        auctor integer congue
                      </p>
                    </div>
                  </div>	
                  {/* FEATURE BOX #6 */}
                  <div id="fb-6-6" className="col-md-6 col-lg-4">
                    <div className="fbox-6 pc-15 mb-50 wow fadeInUp" data-wow-delay="1.4s">
                      {/* Icon & Title */}
                      <div className="fbox-6-title ico-40 grey-color clearfix">
                        <span className="flaticon-spam" />
                        <h5 className="h5-sm">Spam Protection</h5>
                      </div>
                      {/* Text */}
                      <p className="p-lg">Nullam tempor sapien gravida donec blandit ipsum porta justo integer and velna vitae 
                        auctor integer congue
                      </p>
                    </div>
                  </div>	
                </div>  {/* End row */}	
              </div>	{/* END FEATURES-6 WRAPPER */}
            </div>	   {/* End container */}		
          </section>	{/* END FEATURES-6 */}
          {/* TESTIMONIALS-4
			============================================= */}
          <section id="reviews-4" className="bg_aliceblue wide-60 reviews-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-60">		
                    {/* Title */}	
                    <h2 className="h2-md">Why People Use NordEx®</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* TESTIMONIALS-4 WRAPPER */}
              <div className="reviews-4-wrapper">
                <div className="row">
                  {/* TESTIMONIALS LEFT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #1 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="0.4s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Etiam sapien sem at sagittis congue an augue massa varius an egestas suscipit magna a tempus aliquet 
                            blandit <span className="txt-highlight">tempor sapien gravida donec</span> ipsum porta	 			   
                          </p>
                        </div>	
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">John Sweet</h5>
                          <p>Mac Store Review, USA</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #1 */}
                      {/* TESTIMONIAL #2 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>An augue cubilia laoreet undo magna a suscipit egestas magna ipsum at ligula vitae 
                            <span className="txt-highlight">purus ipsum primis</span> cubilia
                          </p>
                        </div>
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* App Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">Mark Hodges</h5>
                          <p>Marketing Manager, Company</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #2 */}
                    </div>
                  </div>	{/* END TESTIMONIALS LEFT COLUMN */}
                  {/* TESTIMONIALS RIGHT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #3 */}
                      <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Mauris donec ociis magnis undo sapien etiam sapien congue augue pretium and ligula augue lectus 
                            aenean  ociis magna donec and magnis sapien sagittis ipsum <span className="txt-highlight">sapien congue tempor gravida </span> a donec ipsum porta justo integer a velna mollis laoreet vitae mauris undo 
                            tortor tempor sapien gravida donec ipsum porta
                          </p>
                        </div>	
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* App Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">Dominic Johnson</h5>
                          <p>Chief Strategist, Company</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #3 */}
                    </div>
                  </div>	{/* END TESTIMONIALS RIGHT COLUMN */}
                </div>  {/* End row */}
              </div>	{/* END TESTIMONIALS-4 WRAPPER */}
              {/* DOWNLOAD BUTTON */}		
              <div className="row">
                <div className="col-md-12">
                  <div className="reviews-download-btn wow fadeInUp" data-wow-delay="1s">
                    {/* STORE BADGES */}												
                    <div className="stores-badge mb-10">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/appstore.png" alt="appstore-badge" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay" src="assets/images/googleplay.png" alt="googleplay-badge" />
                      </a> 
                      {/* Aamazon Market 
									<a href="#" class="store">
										<img class="amazon" src="assets/images/amazon.png" alt="amazon-badge" />
									</a>  */}
                      {/* Mac AppStore 
									<a href="#" class="store">
										<img class="mac-appstore" src="assets/images/macstore.png" alt="macstore-badge" />
									</a> */} 
                      {/* Microsoft Store  
									<a href="#" class="store">
										<img class="microsoft" src="assets/images/microsoft.png" alt="microsoft-badge" />
									</a> */} 
                    </div>	{/* END STORE BADGES */}
                    {/* OS Prerequisite */}
                    <span className="os-version">Available for iOS 8 and Android Devices From 5.5</span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}	
            </div>     {/* End container */}
          </section>	{/* END TESTIMONIALS-4 */}
          {/* CONTENT-13
			============================================= */}
          <section id="content-13" className="wide-60 content-section division">
            <div className="container">
              <div className="row d-flex align-items-center">
                {/* TEXT BLOCK */}		
                <div className="col-md-6 col-xl-6">
                  <div className="txt-block left-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id">Totally Optimized</span>
                    {/* Title */}	
                    <h2 className="h2-md">Intuitive features, powerful results</h2>
                    {/* List */}	
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium 
                          ligula rutrum tempor sapien
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo 
                          pretium a purus mauris
                        </p>
                      </li>
                    </ul>
                  </div>	
                </div>	{/* END TEXT BLOCK */}		
                {/* IMAGE BLOCK */}	
                <div className="col-md-6 col-xl-6">
                  <div className="content-13-img wow fadeInRight" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/tablet.png" alt="content-image" />
                  </div>	
                </div>
              </div>	  {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-13 */}
          {/* BRANDS-1
			============================================= */}
          <div id="brands-1" className="bg_whitesmoke brands-section division">
            <div className="container">					
              <div className="row">
                <div className="col text-center pc-25">	
                  <div className="owl-carousel brands-carousel">
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-1.png" alt="brand-logo" />
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-2.png" alt="brand-logo" />
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-3.png" alt="brand-logo" />
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-4.png" alt="brand-logo" />
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-5.png" alt="brand-logo" />
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-6.png" alt="brand-logo" />
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-7.png" alt="brand-logo" />
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-8.png" alt="brand-logo" />
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-9.png" alt="brand-logo" />
                    </div>
                  </div>
                </div>
              </div>      {/* End row */}
            </div>	    {/* End container */}
          </div>	{/* END BRANDS-1 */}
          {/* CONTENT-11
			============================================= */}
          <section id="content-11" className="wide-100 content-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-60">		
                    {/* Title */}	
                    <h2 className="h2-md">Accessible For All Platforms</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* IMAGE BLOCK */}
              <div className="row">
                <div className="col-md-12">
                  <div className="img-block pc-30 text-center wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/browsers.png" alt="content-image" />
                  </div>
                </div>
              </div>
              {/* DOWNLOAD BUTTON */}		
              <div className="row">
                <div className="col-md-12">
                  <div className="content-11-btn mt-60 wow fadeInUp" data-wow-delay="0.6s">
                    {/* Button */}
                    <a href="https://www.youtube.com/watch?v=7e90gBu4pas" className="video-popup2 btn btn-md btn-grey skyblue-hover mb-10">
                      See NordEx in Action
                    </a> 
                    {/* OS Prerequisite */}
                    <span className="os-version">Request OS X 10.10 or later</span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}	
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-11 */}
          {/* DOWNLOAD-6
			============================================= */}
          <section id="download-6" className="bg_chat wide-100 download-section division">
            <div className="container white-color">
              <div className="row">	
                {/* DOWNLOAD TXT */}	
                <div className="col-lg-6">	
                  <div className="download-6-txt pl-20 wow fadeInUp" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id">Powerful Settings</span>
                    {/* Title 	*/}	
                    <h2 className="h2-md">Take Full Advantage of Smart Features</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit 
                      posuere orci auctor purus euismod an aliquam quaerat purus
                    </p>
                    {/* STORE BADGES */}												
                    <div className="stores-badge">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/appstore.png" alt="appstore-badge" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay" src="assets/images/googleplay.png" alt="googleplay-badge" />
                      </a> 
                      {/* Aamazon Market 
									<a href="#" class="store">
										<img class="amazon" src="assets/images/amazon.png" alt="amazon-badge" />
									</a>  */}
                      {/* Mac AppStore 
									<a href="#" class="store">
										<img class="mac-appstore" src="assets/images/macstore.png" alt="macstore-badge" />
									</a> */} 
                      {/* Microsoft Store  
									<a href="#" class="store">
										<img class="microsoft" src="assets/images/microsoft.png" alt="microsoft-badge" />
									</a> */} 
                    </div>	{/* END STORE BADGES */}	
                  </div>
                </div>	{/* END DOWNLOAD TXT */}	
              </div>    {/* End row */}
            </div>	   {/* End container */}	
          </section>	{/* END  DOWNLOAD-6 */}
          {/* PRICING-2
			============================================= */}
          <section id="pricing-2" className="wide-90 pricing-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Simple and Flexible Pricing</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* PRICING TABLES */}
              <div className="row pricing-row">
                {/* FREE PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.4s">	
                    {/* Plan Price  */}
                    <div className="pricing-plan bg_white">
                      <h5 className="h5-xs">NordEx Free</h5>									
                      <sup className="dark-color">$</sup>								
                      <span className="dark-color">0</span>
                      <sup className="validity dark-color"><span>.00</span> / month</sup>
                      <p className="p-sm">The price per one user. Change or cancel your plan anytime</p>
                      {/* Pricing Table Button  */}
                      <a href="#" className="btn btn-tra-grey skyblue-hover">Download Now</a>
                    </div>	
                  </div>
                </div>	{/* END FREE PLAN */}
                {/* MONTHLY PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.6s">	
                    {/* Plan Price  */}
                    <div className="pricing-plan bg_white">
                      <h5 className="h5-xs">Monthly Billing</h5>	
                      <sup className="dark-color">$</sup>								
                      <span className="dark-color">6</span>
                      <sup className="validity dark-color"><span>.25</span> / month</sup>
                      <p className="p-sm">The price per one user. Change or cancel your plan anytime</p>
                      {/* Pricing Table Button */}
                      <a href="#" className="btn btn-tra-grey skyblue-hover">Select Plan</a>
                    </div>	
                  </div>
                </div>	{/* END MONTHLY PLAN  */}
                {/* ANNUAL PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table rel mb-40 wow fadeInUp" data-wow-delay="0.8s">
                    {/* Hightlight Badge */}
                    <div className="badge-wrapper">
                      <div className="highlight-badge bg_skyblue white-color">
                        <h6 className="h6-sm">Save up to 25%</h6>
                      </div>
                    </div>	
                    {/* Plan Price  */}
                    <div className="pricing-plan highlight bg_whitesmoke">
                      <h5 className="h5-xs">Annual Billing</h5>	
                      <sup className="dark-color">$</sup>								
                      <span className="dark-color">5</span>
                      <sup className="validity dark-color"><span>.69</span> / month</sup>
                      <p className="p-sm">The price per one user. Change or cancel your plan anytime</p>
                      {/* Pricing Table Button */}
                      <a href="#" className="btn btn-tra-grey skyblue-hover">Select Plan</a>
                    </div>	
                  </div>
                </div>	{/* END ANNUAL PLAN */}
              </div>	{/* END PRICING TABLES */}
              {/* DOWNLOAD BUTTON */}		
              <div className="row">
                <div className="col-md-12">
                  <div className="pricing-2-download-btn mt-20 text-center wow fadeInUp" data-wow-delay="0.8s">
                    {/* Button */}
                    <a href="pricing.html" className="btn btn-md btn-tra-grey skyblue-hover mb-10">Start Free 14-day Trial</a>
                    {/* OS Prerequisite */}
                    <span className="os-version">Request OS X 10.10 or later</span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}	
            </div>	   {/* End container */}
          </section>	{/* END PRICING-2 */}
          {/* CONTENT-1
			============================================= */}
          <section id="content-1" className="bg_whitesmoke wide-60 content-section division">
            <div className="container">
              <div className="row d-flex align-items-center m-row">
                {/* TEXT BLOCK */}	
                <div className="col-md-7 col-lg-6 m-bottom">
                  <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id grey-color">Register in 30 Seconds</span>
                    {/* Title */}	
                    <h2 className="h2-md">Lightning fast and super powerful</h2>
                    {/* Text */}	
                    <p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue 
                      luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat 
                      sodales a sapien
                    </p>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6 m-top">
                  <div className="img-block right-column pc-20 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/img-01.png" alt="content-image" />
                  </div>
                </div>
              </div>	   {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-1 */}
          {/* FAQs-2
			============================================= */}
          <section id="faqs-2" className="wide-100 faqs-section division">				
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Got Questions? Look Here.</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* FAQs-2 QUESTIONS */}	
              <div className="faqs-2-questions pc-15">
                <div className="row">
                  {/* QUESTIONS HOLDER */}
                  <div className="col-lg-6">
                    <div className="questions-holder pc-10">
                      {/* QUESTION #1 */}
                      <div className="question wow fadeInUp" data-wow-delay="0.4s">
                        {/* Question */}
                        <h5 className="h5-sm">Can I see NordEx in action before purchasing it?</h5>
                        {/* Answer */}
                        <p className="p-lg">Etiam amet mauris suscipit in odio integer congue metus vitae arcu mollis blandit 
                          ultrice ligula egestas and magna suscipit lectus magna suscipit luctus blandit vitae
                        </p>
                      </div>	
                      {/* QUESTION #2 */}					
                      <div className="question wow fadeInUp" data-wow-delay="0.6s">
                        {/* Question */}
                        <h5 className="h5-sm">What are the requirements for using NordEx?</h5>
                        {/* Answer */}
                        <p className="p-lg">An enim nullam tempor sapien gravida donec ipsum enim an porta justo integer at velna 
                          vitae auctor integer congue undo magna at pretium purus pretium ligula 
                        </p>
                      </div>
                      {/* QUESTION #3 */}					
                      <div className="question wow fadeInUp" data-wow-delay="0.8s">
                        {/* Question */}
                        <h5 className="h5-sm">Can I use NordEx on different devices?</h5>
                        {/* Answer */}
                        <ul className="simple-list">
                          <li className="list-item">
                            <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus ligula euismod pretium purus
                              pretium rutrum tempor sapien
                            </p>
                          </li>
                          <li className="list-item">
                            <p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo 
                              pretium a purus
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>	{/* END QUESTIONS HOLDER */}
                  {/* QUESTIONS HOLDER */}
                  <div className="col-lg-6">
                    <div className="questions-holder pc-10">
                      {/* QUESTION #4 */}					
                      <div className="question wow fadeInUp" data-wow-delay="0.4s">
                        {/* Question */}
                        <h5 className="h5-sm">Do you have a free trial?</h5>
                        {/* Answer */}
                        <p className="p-lg">Cubilia laoreet augue egestas and luctus donec curabite diam vitae dapibus libero and 
                          quisque gravida donec neque. Blandit justo aliquam molestie nunc sapien justo
                        </p>
                      </div>
                      {/* QUESTION #5 */}
                      <div className="question wow fadeInUp" data-wow-delay="0.6s">
                        {/* Question */}
                        <h5 className="h5-sm">How does NordEx handle my privacy?</h5>
                        {/* Answer */}
                        <p className="p-lg">Etiam amet mauris suscipit sit amet in odio. Integer congue leo metus. Vitae arcu mollis 
                          blandit ultrice ligula
                        </p>
                        {/* Answer */}
                        <p className="p-lg">An enim nullam tempor sapien gravida donec congue leo metus. Vitae arcu mollis blandit 
                          integer at velna
                        </p>
                      </div>
                      {/* QUESTION #6 */}
                      <div className="question wow fadeInUp" data-wow-delay="0.6s">
                        {/* Question */}
                        <h5 className="h5-sm">I have an issue with my account</h5>
                        {/* Answer */}
                        <ul className="simple-list">
                          <li className="list-item">
                            <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus</p>
                          </li>
                          <li className="list-item">
                            <p className="p-lg">Quaerat sodales sapien euismod blandit purus and ipsum primis in cubilia laoreet 
                              augue luctus
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>	{/* END QUESTIONS HOLDER */}
                </div>	{/* End row */}
              </div>	{/* END FAQs-2 QUESTIONS */}	
              {/* MORE QUESTIONS BUTTON */}	
              <div className="row">
                <div className="col-md-12">	
                  <div className="more-questions text-center mt-40">
                    <h5 className="h5-sm"><span className="flaticon-check" /> 
                      Have more questions? <a href="contacts.html" className="skyblue-color">Ask your question here</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>	   {/* End container */}		
          </section>	{/* END FAQs-2 */}
          {/* NEWSLETTER-1
			============================================= */}
          <section id="newsletter-1" className="pb-20 newsletter-section division">
            <div className="container">
              <div className="newsletter-wrapper bg_white">
                <div className="row d-flex align-items-center">
                  {/* SECTION TITLE */}	
                  <div className="col-lg-6">
                    <div className="newsletter-txt">	
                      {/* Section ID */}	
                      <span className="section-id">Subscribe to Our Newsletter</span>
                      {/* Title */}	
                      <h4 className="h4-xl">Stay up to date with our news, ideas and updates</h4>	
                    </div>								
                  </div>
                  {/* NEWSLETTER FORM */}
                  <div className="col-lg-6">
                    <form className="newsletter-form">
                      <div className="input-group">
                        <input type="email" autoComplete="off" className="form-control" placeholder="Your email address" required id="s-email" />								
                        <span className="input-group-btn">
                          <button type="submit" className="btn btn-md btn-skyblue tra-skyblue-hover">Subscribe Now</button>
                        </span>										
                      </div>
                      {/* Newsletter Form Notification */}	
                      <label htmlFor="s-email" className="form-notification" />
                    </form>							
                  </div>	  {/* END NEWSLETTER FORM */}
                </div>	  {/* End row */}
              </div>    {/* End newsletter-holder */}
            </div>	   {/* End container */}	
          </section>	{/* END NEWSLETTER-1 */}
          {/* BLOG-1
			============================================= */}
          <section id="blog-1" className="wide-60 blog-section division">				
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Our Stories &amp; Latest News</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* BLOG POSTS */}
              <div className="row">
                {/* BLOG POST #1 */}
                <div id="b-post-1" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.4s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-1-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">NordEx News</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Tempor sapien donec gravida ipsum a porta justo vitae</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>12 min read</p>
                      </div>	
                    </div>
                  </div>
                </div>	{/* END  BLOG POST #1 */}
                {/* BLOG POST #2 */}
                <div id="b-post-2" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.6s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-2-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">Inspiration</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Aliquam augue impedit luctus neque purus an ipsum neque and dolor libero risus</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>8 min read</p>
                      </div>	
                    </div>	{/* END BLOG POST TEXT */}
                  </div>
                </div>	{/* END  BLOG POST #2 */}
                {/* BLOG POST #3 */}
                <div id="b-post-3" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.8s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-3-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">Tutorials</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Tempor sapien NordEx, donec gravida ipsum a porta justo</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>22 min read</p>
                      </div>	
                    </div>	{/* END BLOG POST TEXT */}
                  </div>
                </div>	{/* END  BLOG POST #3 */}
              </div>	{/* END BLOG POSTS */}
            </div>	   {/* End container */}		
          </section>	{/* END BLOG-1 */}
          {/* MORE APPS-1
			============================================= */}
          <section id="more-apps-1" className="bg_whitesmoke wide-70 moreapps-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">More Apps by DSAThemes</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* MORE APPS-1 WRAPPER */}	
              <div className="abox-1-wrapper">
                <div className="row">
                  {/* APP #1 LINK */}
                  <div className="col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                    <a href="#">
                      <div className="row abox-1 d-flex align-items-center">
                        {/* App Version */}		
                        <div className="app-version"><span>2.0.1</span></div>
                        {/* Logo */}
                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
                          <img className="img-fluid" src="assets/images/app-logo-1.png" alt="app-logo" />
                        </div>
                        {/* Text */}	
                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	
                          <h5 className="h5-xs">FastChat 2</h5>
                          <p>Aliquam an augue luctus undo purus</p>
                          {/* App Rating */}
                          <div className="app-rating ico-20">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty mr-5" />	
                            4.3
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>	{/* END APP #1 LINK */}
                  {/* APP #2 LINK */}
                  <div className="col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                    <a href="#">
                      <div className="row abox-1 d-flex align-items-center">
                        {/* App Version */}		
                        <div className="app-version"><span>1.3.9</span></div>
                        {/* Logo */}
                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
                          <img className="img-fluid" src="assets/images/app-logo-2.png" alt="app-logo" />
                        </div>
                        {/* Text */}	
                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	
                          <h5 className="h5-xs">MuMemos</h5>
                          <p>Aliquam an augue luctus undo purus</p>
                          {/* App Rating */}
                          <div className="app-rating ico-20">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star mr-5" />	
                            5.0
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>	{/* END APP #2 LINK */}
                  {/* APP #3 LINK */}
                  <div className="col-md-6 wow fadeInUp" data-wow-delay="0.8s">
                    <a href="#">
                      <div className="row abox-1 d-flex align-items-center">
                        {/* App Version */}		
                        <div className="app-version"><span>4.1.6</span></div>
                        {/* Logo */}
                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
                          <img className="img-fluid" src="assets/images/app-logo-4.png" alt="app-logo" />
                        </div>
                        {/* Text */}	
                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	
                          <h5 className="h5-xs">Music Player</h5>
                          <p>Aliquam an augue luctus undo purus</p>
                          {/* App Rating */}
                          <div className="app-rating ico-20">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty mr-5" />	
                            4.65
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>	{/* END APP #3 LINK */}
                  {/* APP #4 LINK */}
                  <div className="col-md-6 wow fadeInUp" data-wow-delay="1s">
                    <a href="#">
                      <div className="row abox-1 d-flex align-items-center">
                        {/* App Version */}		
                        <div className="app-version"><span>14.1.0</span></div>
                        {/* Logo */}
                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
                          <img className="img-fluid" src="assets/images/app-logo-3.png" alt="app-logo" />
                        </div>
                        {/* Text */}	
                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	
                          <h5 className="h5-xs">PowerSaver</h5>
                          <p>Aliquam an augue luctus undo purus</p>
                          {/* App Rating */}
                          <div className="app-rating ico-20">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty mr-5" />	
                            4.21
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>	{/* END APP #4 LINK */}
                  {/* APP #5 LINK */}
                  <div className="col-md-6 wow fadeInUp" data-wow-delay="1.2s">
                    <a href="#">
                      <div className="row abox-1 d-flex align-items-center">
                        {/* App Version */}		
                        <div className="app-version"><span>2.2.1</span></div>
                        {/* Logo */}
                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
                          <img className="img-fluid" src="assets/images/app-logo-5.png" alt="app-logo" />
                        </div>
                        {/* Text */}	
                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	
                          <h5 className="h5-xs">StocksHub</h5>
                          <p>Aliquam an augue luctus undo purus</p>
                          {/* App Rating */}
                          <div className="app-rating ico-20">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty mr-5" />	
                            4.45
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>	{/* END APP #5 LINK */}
                  {/* APP #6 LINK */}
                  <div className="col-md-6 wow fadeInUp" data-wow-delay="1.4s">
                    <a href="#">
                      <div className="row abox-1 d-flex align-items-center">
                        {/* App Version */}		
                        <div className="app-version"><span>2.3.5</span></div>
                        {/* Logo */}
                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
                          <img className="img-fluid" src="assets/images/app-logo-6.png" alt="app-logo" />
                        </div>
                        {/* Text */}	
                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	
                          <h5 className="h5-xs">Equalizer 2</h5>
                          <p>Aliquam an augue luctus undo purus</p>
                          {/* App Rating */}
                          <div className="app-rating ico-20">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star mr-5" />
                            4.0
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>	{/* END APP #6 LINK */}
                </div>    {/* End row */}
              </div>	{/* END MORE APPS-1 WRAPPER */}	
            </div>	   {/* End container */}
          </section>	{/* END MORE APPS-1 */}
         
        </div>	{/* END PAGE CONTENT */}
      
        
            </div>
        )
    }
}
export default Home_9 