import React from 'react';
import { Routes, Route } from "react-router-dom";

import Header from './components/Header';
import Footer from './components/Footer';
import Home from './pages/Home';
import Faqs from './pages/Faqs';
import Features from './pages/Features';
import MoreApps from './pages/MoreApps';
import Pricing from './pages/Pricing';
import Reviews from './pages/Reviews';
import SinglePost from './pages/SinglePost';
import Terms from './pages/Terms';
import Demo from './pages/Demo';
import About from './pages/About';
import BlogListing from './pages/BlogListing';
import Contacts from './pages/Contacts';
import Home2 from './pages/Home2';
import Home3 from './pages/Home3';
import Home4 from './pages/Home4';
import Home5 from './pages/Home5';
import Home6 from './pages/Home6';
import Home7 from './pages/Home7';
import Home8 from './pages/Home8';
import Home9 from './pages/Home9';
import Home10 from './pages/Home10';
import Home11 from './pages/Home11';
import Home12 from './pages/Home12';

function App() {
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route path="/" element={<Home4 />} />
        <Route path="/about" element={<About />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/features" element={<Features />} />
        <Route path="/more_apps" element={<MoreApps />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/reviews" element={<Reviews />} />
        <Route path="/single_post" element={<SinglePost />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/demo" element={<Demo />} />
        <Route path="/blog_listing" element={<BlogListing />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/home_2" element={<Home2 />} />
        <Route path="/home_3" element={<Home3 />} />
        <Route path="/home_4" element={<Home4 />} />
        <Route path="/home_5" element={<Home5 />} />
        <Route path="/home_6" element={<Home6 />} />
        <Route path="/home_7" element={<Home7 />} />
        <Route path="/home_8" element={<Home8 />} />
        <Route path="/home_9" element={<Home9 />} />
        <Route path="/home_10" element={<Home10 />} />
        <Route path="/home_11" element={<Home11 />} />
        <Route path="/home_12" element={<Home12 />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
