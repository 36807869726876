import React, {component} from 'react';
import { Link } from "react-router-dom";

class Footer extends React.Component {
    render(){
        return(
            <div>
<footer id="footer-3" className="footer division">
				<div className="container">
					<div className="row">
						<div className="col-lg-10 offset-lg-1">
							<div className="row">
								<div className="col-sm-6 col-md-4 col-lg-3">
									<div className="footer-links mb-40">
										<ul className="foo-links clearfix">									
											<li><p className="p-md"><Link to="/#">Fiyatlandırmalarımız</Link></p></li>			
											<li><p className="p-md"><Link to="/home_4">Destek</Link></p></li>	
											<li><p className="p-md"><Link to="/terms">Gizlilik Politikası</Link></p></li>					


										</ul>
									</div>
								</div>
								<div className="col-sm-8 col-md-4 col-lg-3">
									<div className="footer-links mb-40">
										<ul className="foo-links clearfix">							
										</ul>
									</div>
								</div>
								<div className="col-sm-6 col-md-4 col-lg-3">
									<div className="footer-links mb-40">
										<ul className="foo-links clearfix">
										</ul>
									</div>
								</div>
								<div className="col-sm-6 col-md-8 col-lg-3">
									<div className="footer-store-badges text-right mb-40">
										<Link to="#" className="store">
											<img className="appstore" 
											
											src={`${process.env.PUBLIC_URL}/assets/images/appstore.png`}
											
											alt="appstore-badge" />
										</Link>
										<Link to="#" className="store">
											<img className="googleplay" 
											
											src={`${process.env.PUBLIC_URL}/assets/images/googleplay.png`}
											
											alt="googleplay-badge" />
										</Link> 
									</div>
								</div>	

							</div>
						</div>
					</div>	 					
					<div className="row">
						<div className="col-lg-10 offset-lg-1">
							<div className="bottom-footer footer-copyright text-center">
								<p>&copy; 2024 Workfray. Tüm Hakları Saklıdır</p>
							</div>
						</div>
					</div>
				</div>	   										
			</footer>	
            </div>
        )
    }
}
export default Footer    