import React, {Component} from 'react';

class Home_12 extends React.Component {
    render() {
        return(
            <div>


        {/* PRELOADER SPINNER
		============================================= */}	
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
		============================================= */}	
        <div id="page" className="page">
          {/* HEADER
			============================================= */}
          
          {/* HERO-12
			============================================= */}	
          <section id="hero-12" className="bg_scroll hero-section division">
            <div className="container white-color">				
              <div className="row d-flex align-items-center">
                {/* HERO TEXT */}
                <div className="col-md-6 col-lg-5">
                  <div className="hero-12-txt pc-20 text-center wow fadeInRight" data-wow-delay="0.6s">
                    {/* Title  */}
                    <h2 className="h2-title-xl">NordEx®</h2>
                    {/* Text */}
                    <h4 className="h4-md">Beautifully Designed, Fully Customizable</h4>
                    {/* STORE BADGES */}												
                    <div className="stores-badge mb-10">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/appstore.png" alt="appstore-badge" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay" src="assets/images/googleplay.png" alt="googleplay-badge" />
                      </a> 
                      {/* Aamazon Market 
									<a href="#" class="store">
										<img class="amazon" src="assets/images/amazon.png" alt="amazon-badge" />
									</a>  */}
                      {/* Mac AppStore 
									<a href="#" class="store">
										<img class="mac-appstore" src="assets/images/macstore.png" alt="macstore-badge" />
									</a> */} 
                      {/* Microsoft Store  
									<a href="#" class="store">
										<img class="microsoft" src="assets/images/microsoft.png" alt="microsoft-badge" />
									</a> */}
                    </div>	{/* END STORE BADGES */}
                    {/* OS Prerequisite */}
                    <span className="os-version">Current Release: NordEx 2.1.09</span>	
                  </div>
                </div> 	  {/* END HERO TEXT */}
                {/* HERO IMAGE */}							
                <div className="col-md-6 col-lg-7">	
                  <div className="hero-12-img pc-25 text-center wow fadeInLeft" data-wow-delay="0.6s">							
                    <img className="img-fluid" src="assets/images/hero-12-img.png" alt="hero-image" />											
                  </div>							
                </div>
              </div>	  {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END HERO-12 */}
          {/* FEATURES-7
			============================================= */}
          <section id="features-7" className="wide-100 features-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-md-10 col-lg-8 offset-md-1 offset-lg-2">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Make the most of your account, wherever you are</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius
                    </p>
                  </div>	
                </div>
              </div>
              {/* FEATURES-7 WRAPPER */}
              <div className="fbox-7-wrapper">
                <div className="row">
                  {/* FEATURE BOX #1 */}
                  <div id="fb-7-1" className="col-sm-6 col-md-3">
                    <div className="fbox-7 text-center wow fadeInUp" data-wow-delay="0.4s">
                      {/* Icon */}
                      <div className="fbox-7-ico ico-75 grey-color"><span className="flaticon-ads" /></div> 
                      {/* Title */}
                      <h5 className="h5-xs">Friendly Interface</h5>
                    </div>
                  </div>	
                  {/* FEATURE BOX #2 */}
                  <div id="fb-7-2" className="col-sm-6 col-md-3">
                    <div className="fbox-7 text-center wow fadeInUp" data-wow-delay="0.6s">
                      {/* Icon */}
                      <div className="fbox-7-ico ico-75 grey-color"><span className="flaticon-manufacturing" /></div>
                      {/* Title */}
                      <h5 className="h5-xs">Powerful Options</h5>
                    </div>
                  </div>	
                  {/* FEATURE BOX #3 */}
                  <div id="fb-7-3" className="col-sm-6 col-md-3">
                    <div className="fbox-7 text-center wow fadeInUp" data-wow-delay="0.8s">
                      {/* Icon */}
                      <div className="fbox-7-ico ico-75 grey-color"><span className="flaticon-user-1" /></div>
                      {/* Title */}
                      <h5 className="h5-xs">Multiple Accounts</h5>
                    </div>
                  </div>	
                  {/* FEATURE BOX #4 */}
                  <div id="fb-7-4" className="col-sm-6 col-md-3">
                    <div className="fbox-7 text-center wow fadeInUp" data-wow-delay="1s">
                      {/* Icon */}
                      <div className="fbox-7-ico ico-75 grey-color"><span className="flaticon-folder-2" /></div>
                      {/* Title */}
                      <h5 className="h5-xs">Concrete Security</h5>
                    </div>
                  </div>	
                </div>  {/* End row */}	
              </div>	{/* END FEATURES-7 WRAPPER */}
            </div>	   {/* End container */}		
          </section>	{/* END FEATURES-7 */}
          {/* CONTENT-5
			============================================= */}
          <section id="content-5" className="pb-80 content-section">
            <div className="bg-inner bg_whitesmoke pt-100 division">
              <div className="container">
                <div className="row d-flex align-items-center">
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6">
                    <div className="content-5-img right-column pc-20 text-center wow fadeInRight" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img-08.png" alt="content-image" />
                    </div>
                  </div>
                  {/* TEXT BLOCK */}
                  <div className="col-md-7 col-lg-6">
                    <div className="txt-block left-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      {/* Section ID */}	
                      <span className="section-id grey-color">Fast Performance</span>
                      {/* Title */}	
                      <h2 className="h2-md">More productivity with less effort</h2>
                      {/* Text */}
                      <p className="p-lg">Gravida porta velna vitae auctor congue at magna nihil impedit ligula risus. Mauris donec 
                        ligula and magnis 
                      </p>
                      {/* Text */}
                      <p className="p-lg">Aliqum mullam blandit and tempor sapien donec ipsum gravida porta. Velna vitae auctor congue 
                        magna impedit ligula risus. Mauris donec ligula and magnis undo sapien sagittis sapien pretium enim gravida purus ligula
                      </p>
                    </div>	
                  </div>	{/* END CONTENT TXT */}	
                </div>	  {/* End row */}
              </div>	   {/* End container */}
            </div>		{/* End Inner Background */}	
          </section>	{/* END CONTENT-5 */}
          {/* CONTENT-3
			============================================= */}
          <section id="content-3" className="wide-60 mt-inverse-40 content-section division">
            <div className="container">
              {/* CONTENT BOX-1 */}
              <div id="cb-1-1" className="cbox-1 pb-25">
                <div className="row d-flex align-items-center m-row">
                  {/* TEXT BLOCK */}	
                  <div className="col-md-7 col-lg-6 m-bottom">
                    <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      {/* Section ID */}	
                      <span className="section-id grey-color">Register in 30 Seconds</span>
                      {/* Title */}	
                      <h2 className="h2-md">Lightning fast and super powerful</h2>
                      {/* Text */}	
                      <p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue 
                        luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat 
                        sodales a sapien
                      </p>
                    </div>
                  </div>	{/* END TEXT BLOCK */}	
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6 m-top">
                    <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img-01.png" alt="content-image" />
                    </div>
                  </div>
                </div>  {/* End row */}	
              </div>	{/* END CONTENT BOX-1 */}
              {/* CONTENT BOX-2 */}
              <div id="cb-1-2" className="cbox-1 rel">
                <div className="row d-flex align-items-center">
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6">
                    <div className="img-block left-column pc-25 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img-02.png" alt="content-image" />
                    </div>
                  </div>
                  {/* TEXT BLOCK */}	
                  <div className="col-md-7 col-lg-6">
                    <div className="txt-block right-column pc-30 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      {/* Section ID */}	
                      <span className="section-id grey-color">Perfect Integration</span>
                      {/* Title */}	
                      <h2 className="h2-md">Fast and secure access to all your favorite content</h2>
                      {/* List */}	
                      <ul className="simple-list">
                        <li className="list-item">
                          <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium 
                            ligula rutrum tempor sapien
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo 
                            pretium a purus mauris
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>	{/* END TEXT BLOCK */}	
                </div>	  {/* End row */}	
                {/* Transparent Background Elements */}
                <div className="tra-bkg-wrapper">
                  <div className="bg_el_03 wow fadeInDown" data-wow-delay="1s" />
                </div>
              </div>	{/* END CONTENT BOX-2 */}
            </div>     {/* End container */}
          </section>	{/* END CONTENT-3 */}	
          {/* FEATURES-5
			============================================= */}
          <section id="features-5" className="pb-50 features-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Ready to Try NordEx?</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* FEATURES-5 WRAPPER */}
              <div className="fbox-5-wrapper pc-30">
                <div className="row">
                  {/* FEATURE BOX #1 */}
                  <div id="fb-5-1" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.4s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 stateblue-color"><span className="flaticon-call" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Quick Access</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>	
                  {/* FEATURE BOX #2 */}
                  <div id="fb-5-2" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.6s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 stateblue-color"><span className="flaticon-layers" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Multiple Widgets</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* FEATURE BOX #3 */}
                  <div id="fb-5-3" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="0.8s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 stateblue-color"><span className="flaticon-pantone" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Customization</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* FEATURE BOX #4 */}
                  <div id="fb-5-4" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="1s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 stateblue-color"><span className="flaticon-chat-4" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Smart Notifications</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* FEATURE BOX #5 */}
                  <div id="fb-5-5" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="1.2s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 stateblue-color"><span className="flaticon-harddisk-1" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Storage &amp; Backup</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* FEATURE BOX #6 */}
                  <div id="fb-5-6" className="col-md-6">
                    <div className="fbox-5 pc-25 mb-40 wow fadeInUp" data-wow-delay="1.4s">
                      {/* Icon */}
                      <div className="fbox-ico ico-70 stateblue-color"><span className="flaticon-language" /></div>
                      {/* Text */}
                      <div className="fbox-txt">
                        {/* Title */}
                        <h5 className="h5-sm">Multi-language</h5>
                        {/* Text */}
                        <p className="p-lg">Porta semper lacus cursus feugiat primis ultrice ligula risus auctor tempus feugiat 
                          at impedit felis undo auctor augue mauris
                        </p>
                      </div>
                    </div>
                  </div>
                </div>  {/* End row */}	
              </div>	{/* END FEATURES-5 HOLDER */}
            </div>	   {/* End container */}		
          </section>	{/* END FEATURES-5 */}
          {/* CONTENT-12
			============================================= */}
          <section id="content-12" className="mb-60 content-section division">
            <div className="container">
              <div className="lavender_gradient content-12-wrapper">
                <div className="row d-flex align-items-center">
                  {/* TEXT BLOCK */}	
                  <div className="col-lg-5">
                    <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      {/* Title */}	
                      <h2 className="h2-xs">Work smarter with powerful automation</h2>
                      {/* Text */}	
                      <p className="p-lg">Quaerat sodales sapien euismod purus at blandit and purus ipsum primis and cubilia laoreet 
                        augue a luctus magna dolor luctus mauris pretium a sapien egestas luctus
                      </p>
                      {/* Text */}	
                      <p className="p-lg">Fringilla risus, luctus mauris auctor a purus euismod orci pretium purus pretium undo ligula 
                        rutrum tempor magna dolor 
                      </p>
                    </div>
                  </div>	{/* END TEXT BLOCK */}	
                  {/* IMAGE BLOCK */}
                  <div className="col-lg-7">
                    <div className="content-12-img right-column wow fadeInLeft" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img-12.png" alt="content-image" />
                    </div>
                  </div>
                </div>
              </div>    {/* End row */}
            </div>	   {/* End container */}	
          </section>	{/* END CONTENT-12 */}
          {/* CONTENT-2
			============================================= */}
          <section id="content-2" className="wide-50 content-section division">
            <div className="container">
              <div className="row d-flex align-items-center">
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6">
                  <div className="img-block left-column pc-20 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/img-03.png" alt="content-image" />
                  </div>
                </div>
                {/* TEXT BLOCK */}	
                <div className="col-md-7 col-lg-6">
                  <div className="txt-block right-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id grey-color">Beauty of Simplicity</span>
                    {/* Title */}	
                    <h2 className="h2-md">Beautiful, award-winning design</h2>
                    {/* List */}	
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium 
                          ligula rutrum tempor sapien
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo 
                          pretium a purus mauris
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
              </div>	   {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-2 */}
          {/* FEATURES-4
			============================================= */}
          <section id="features-4" className="pb-100 features-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Get Ready to Be Surprised</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* FEATURES-4 WRAPPER */}	
              <div className="fbox-4-wrapper">
                <div className="row">
                  {/* FEATURE BOX #1 */}
                  <div id="fb-4-1" className="col-md-6">
                    <div className="fbox-4 bg_whitesmoke mb-30 wow fadeInUp" data-wow-delay="0.4s">
                      <div className="row d-flex align-items-center m-row">
                        {/* TEXT */}
                        <div className="col-lg-8 m-bottom">
                          <div className="fbox-4-txt">
                            {/* Title */}
                            <h5 className="h5-sm">Dark &amp; Light Modes</h5>
                            {/* Text */}
                            <p className="p-md grey-color">Porta semper lacus cursus risus and feugiat primis undo sodales a 
                              sapien magna at vitae congue tempus
                            </p>
                          </div>
                        </div>
                        {/* ICON */}
                        <div className="col-lg-4 m-top">
                          <div className="fbox-4-ico ico-100 grey-color text-center">
                            <span className="flaticon-paint-brush" />
                          </div>
                        </div>
                      </div>	{/* End row */}
                    </div>  {/* End fbox-4 */}
                  </div>	{/* END FEATURE BOX #1 */}
                  {/* FEATURE BOX #2 */}
                  <div id="fb-4-2" className="col-md-6">
                    <div className="fbox-4 bg_whitesmoke mb-30 wow fadeInUp" data-wow-delay="0.6s">
                      <div className="row d-flex align-items-center m-row">
                        {/* TEXT */}
                        <div className="col-lg-8 m-bottom">
                          <div className="fbox-4-txt">
                            {/* Title */}
                            <h5 className="h5-sm">Auto-Link Opening</h5>
                            {/* Text */}
                            <p className="p-md grey-color">Porta semper lacus cursus risus and feugiat primis undo sodales a 
                              sapien magna at vitae congue gravida
                            </p>
                          </div>
                        </div>
                        {/* ICON */}
                        <div className="col-lg-4 m-top">
                          <div className="fbox-4-ico ico-100 grey-color text-center">
                            <span className="flaticon-roundabout" />
                          </div>
                        </div>
                      </div>	{/* End row */}
                    </div>  {/* End fbox-4 */}
                  </div>	{/* END FEATURE BOX #2 */}
                  {/* FEATURE BOX #3 */}
                  <div id="fb-4-3" className="col-md-6">
                    <div className="fbox-4 bg_whitesmoke wow fadeInUp" data-wow-delay="0.8s">
                      <div className="row d-flex align-items-center m-row">
                        {/* TEXT */}
                        <div className="col-lg-8 m-bottom">
                          <div className="fbox-4-txt">
                            {/* Title */}
                            <h5 className="h5-sm">Voice Assistant</h5>
                            {/* Text */}
                            <p className="p-md grey-color">Porta semper lacus cursus risus and feugiat primis undo sodales a 
                              sapien magna at vitae congue gravida
                            </p>
                          </div>
                        </div>
                        {/* ICON */}
                        <div className="col-lg-4 m-top">
                          <div className="fbox-4-ico ico-100 grey-color text-center">
                            <span className="flaticon-voice-message-1" />
                          </div>
                        </div>
                      </div>	{/* End row */}
                    </div>  {/* End fbox-4 */}
                  </div>	{/* END FEATURE BOX #3 */}
                  {/* FEATURE BOX #4 */}
                  <div id="fb-4-4" className="col-md-6">
                    <div className="fbox-4 bg_whitesmoke wow fadeInUp" data-wow-delay="1s">
                      <div className="row d-flex align-items-center m-row">
                        {/* TEXT */}
                        <div className="col-lg-8 m-bottom">
                          <div className="fbox-4-txt">
                            {/* Title */}
                            <h5 className="h5-sm">Task Scheduling</h5>
                            {/* Text */}
                            <p className="p-md grey-color">Porta semper lacus cursus risus and feugiat primis undo sodales a 
                              sapien magna at vitae congue gravida
                            </p>
                          </div>
                        </div>
                        {/* ICON */}
                        <div className="col-lg-4 m-top">
                          <div className="fbox-4-ico ico-100 grey-color text-center">
                            <span className="flaticon-tasks" />
                          </div>
                        </div>
                      </div>	{/* End row */}
                    </div>  {/* End fbox-4 */}
                  </div>	{/* END FEATURE BOX #4 */}
                </div>  {/* End row */}	
              </div>	{/* END FEATURES-4 WRAPPER */}	
            </div>	   {/* End container */}		
          </section>	{/* END FEATURES-4 */}
          {/* DOWNLOAD-6
			============================================= */}
          <section id="download-6" className="bg_mobile wide-100 download-section division">
            <div className="container white-color">
              <div className="row">	
                {/* DOWNLOAD TXT */}	
                <div className="col-lg-6">	
                  <div className="download-6-txt pl-20 wow fadeInUp" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id">Powerful Settings</span>
                    {/* Title 	*/}	
                    <h2 className="h2-md">Take Full Advantage of Smart Features</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit 
                      posuere orci auctor purus euismod an aliquam quaerat purus
                    </p>
                    {/* STORE BADGES */}												
                    <div className="stores-badge">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/appstore.png" alt="appstore-badge" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay" src="assets/images/googleplay.png" alt="googleplay-badge" />
                      </a> 
                      {/* Aamazon Market 
									<a href="#" class="store">
										<img class="amazon" src="assets/images/amazon.png" alt="amazon-badge" />
									</a>  */}
                      {/* Mac AppStore 
									<a href="#" class="store">
										<img class="mac-appstore" src="assets/images/macstore.png" alt="macstore-badge" />
									</a> */} 
                      {/* Microsoft Store  
									<a href="#" class="store">
										<img class="microsoft" src="assets/images/microsoft.png" alt="microsoft-badge" />
									</a> */} 
                    </div>	{/* END STORE BADGES */}	
                  </div>
                </div>	{/* END DOWNLOAD TXT */}	
              </div>    {/* End row */}
            </div>	   {/* End container */}	
          </section>	{/* END  DOWNLOAD-6 */}
          {/* CONTENT-11
			============================================= */}
          <section id="content-11" className="wide-100 content-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-60">		
                    {/* Title */}	
                    <h2 className="h2-md">Accessible for All Platforms</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* IMAGE BLOCK */}
              <div className="row">
                <div className="col-md-12">
                  <div className="img-block pc-30 text-center wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/browsers.png" alt="content-image" />
                  </div>
                </div>
              </div>
              {/* DOWNLOAD BUTTON */}		
              <div className="row">
                <div className="col-md-12">
                  <div className="content-11-btn ico-right mt-60 wow fadeInUp" data-wow-delay="0.6s">
                    {/* Button */}
                    <a href="https://www.youtube.com/watch?v=7e90gBu4pas" className="video-popup2 btn btn-md btn-tra-grey yellow-hover mb-10">
                      See NordEx in Action <span className="flaticon-play-button" />
                    </a> 
                    {/* OS Prerequisite */}
                    <span className="os-version">Available on Android, iOS and macOS</span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}	
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-11 */}
          {/* TESTIMONIALS-2
			============================================= */}
          <section id="reviews-2" className="rel reviews-section division">
            <div className="container">
              <div className="row">
                {/* TESTIMONIALS TITLE */}
                <div className="col-lg-4">
                  <div className="reviews-2-title pc-10">
                    {/* Section ID */}	
                    <span className="section-id grey-color">Reviews</span>
                    {/* Title */}
                    <h2 className="h2-md mb-20">Our Happy Customers</h2>
                    {/* Text */}	
                    <p className="p-lg">Aliquam augue suscipit luctus neque purus ipsum neque dolor primis undo tempus, blandit and 
                      cursus varius
                    </p>
                  </div>
                </div>
                {/* TESTIMONIALS CAROUSEL */}
                <div className="col-lg-8">					
                  <div className="owl-carousel owl-theme reviews-2-wrapper">
                    {/* TESTIMONIAL #1 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Great Flexibility!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Etiam sapien sem at sagittis congue an augue massa varius egestas undo 
                          suscipit magna tempus undo aliquet				   
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Scott Boxer</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #1 */}
                    {/* TESTIMONIAL #2 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Simple and Useful!</h5>	
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">At sagittis congue augue undo egestas magna ipsum vitae purus and ipsum 
                          primis suscipit
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Wendy T.</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #2 */}
                    {/* TESTIMONIAL #3 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">I love this App!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Mauris donec ociis magnis and sapien etiam sapien congue undo augue pretium 
                          and ligula augue a lectus aenean magna
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- pebz13</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #3 */}
                    {/* TESTIMONIAL #4 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-1" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Best App for iOS!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">An augue in cubilia laoreet magna and suscipit egestas magna ipsum purus ipsum 
                          and suscipit
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Scott Boxer</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #4 */}
                    {/* TESTIMONIAL #5 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Awesome Design!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Mauris donec magnis sapien undo etiam sapien and congue augue egestas ultrice 
                          a vitae purus velna integer tempor
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- John Sweet</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #5 */}
                    {/* TESTIMONIAL #6 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Simply Amazing App!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">An augue cubilia laoreet undo magna a suscipit undo egestas magna ipsum ligula 
                          vitae purus ipsum primis cubilia blandit
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Leslie D.</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #6 */}
                    {/* TESTIMONIAL #7 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Powerful Features!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Augue egestas volutpat and egestas augue in cubilia laoreet magna undo 
                          suscipit luctus
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- Marisol19</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #7 */}
                    {/* TESTIMONIAL #8 */}
                    <div className="review-2 radius-08">
                      {/* App Rating */}
                      <div className="app-rating ico-20 yellow-color">
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />
                      </div>
                      {/* Title */}
                      <h5 className="h5-sm">Super Support!</h5>
                      {/* Testimonial Text */}
                      <div className="review-2-txt">
                        {/* Text */}
                        <p className="p-lg grey-color">Aliquam augue suscipit luctus neque purus ipsum neque dolor primis libero tempus 
                          at blandit posuere varius magna
                        </p>
                        {/* Testimonial Author */}
                        <h5 className="h5-xs">- AJ</h5>	
                      </div>	
                    </div>	{/* END TESTIMONIAL #8 */}
                  </div>
                </div>	{/* END TESTIMONIALS CAROUSEL */} 
              </div>	
            </div>     {/* End container */}
          </section>	{/* END TESTIMONIALS-2 */}
          {/* BRANDS-2
			============================================= */}
          <section id="brands-2" className="wide-60 brands-section division">
            <div className="container">	
              {/* BRANDS TITLE */}
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  <div className="brands-title text-center">
                    <h4 className="h4-sm">You might know NordEx from:</h4>
                  </div>
                </div>
              </div>
              {/* BRANDS-2 WRAPPER */}
              <div className="brands-2-wrapper">
                <div className="row">
                  <div className="col-md-12">
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand-1.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand-2.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand-3.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand-4.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand-5.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand-6.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand-7.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand-8.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand-9.png" alt="brand-logo" />
                      </a>
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <a href="#">
                        <img className="img-fluid" src="assets/images/brand-10.png" alt="brand-logo" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>  {/* END BRANDS-2 WRAPPER */}
            </div>     {/* End container */}
          </section>	{/* END BRANDS-2 */}
          {/* SECTION DIVIDER
			============================================= */}
          <div className="divider-wrapper text-center"><div className="section-divider" /></div>
          {/* FAQs-2
			============================================= */}
          <section id="faqs-2" className="wide-100 faqs-section division">				
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Got Questions? Look Here</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* FAQs-2 QUESTIONS */}	
              <div className="faqs-2-questions pc-15">
                <div className="row">
                  {/* QUESTIONS HOLDER */}
                  <div className="col-lg-6">
                    <div className="questions-holder pc-10">
                      {/* QUESTION #1 */}
                      <div className="question wow fadeInUp" data-wow-delay="0.4s">
                        {/* Question */}
                        <h5 className="h5-sm">Can I see NordEx in action before purchasing it?</h5>
                        {/* Answer */}
                        <p className="p-lg">Etiam amet mauris suscipit in odio integer congue metus vitae arcu mollis blandit 
                          ultrice ligula egestas and magna suscipit lectus magna suscipit luctus blandit vitae
                        </p>
                      </div>	
                      {/* QUESTION #2 */}					
                      <div className="question wow fadeInUp" data-wow-delay="0.6s">
                        {/* Question */}
                        <h5 className="h5-sm">What are the requirements for using NordEx?</h5>
                        {/* Answer */}
                        <p className="p-lg">An enim nullam tempor sapien gravida donec ipsum enim an porta justo integer at velna 
                          vitae auctor integer congue undo magna at pretium purus pretium ligula 
                        </p>
                      </div>
                      {/* QUESTION #3 */}					
                      <div className="question wow fadeInUp" data-wow-delay="0.8s">
                        {/* Question */}
                        <h5 className="h5-sm">Can I use NordEx on different devices?</h5>
                        {/* Answer */}
                        <ul className="simple-list">
                          <li className="list-item">
                            <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus ligula euismod pretium purus
                              pretium rutrum tempor sapien
                            </p>
                          </li>
                          <li className="list-item">
                            <p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo 
                              pretium a purus
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>	{/* END QUESTIONS HOLDER */}
                  {/* QUESTIONS HOLDER */}
                  <div className="col-lg-6">
                    <div className="questions-holder pc-10">
                      {/* QUESTION #4 */}					
                      <div className="question wow fadeInUp" data-wow-delay="0.4s">
                        {/* Question */}
                        <h5 className="h5-sm">Do you have a free trial?</h5>
                        {/* Answer */}
                        <p className="p-lg">Cubilia laoreet augue egestas and luctus donec curabite diam vitae dapibus libero and 
                          quisque gravida donec neque. Blandit justo aliquam molestie nunc sapien justo
                        </p>
                      </div>
                      {/* QUESTION #5 */}
                      <div className="question wow fadeInUp" data-wow-delay="0.6s">
                        {/* Question */}
                        <h5 className="h5-sm">How does NordEx handle my privacy?</h5>
                        {/* Answer */}
                        <p className="p-lg">Etiam amet mauris suscipit sit amet in odio. Integer congue leo metus. Vitae arcu mollis 
                          blandit ultrice ligula
                        </p>
                        {/* Answer */}
                        <p className="p-lg">An enim nullam tempor sapien gravida donec congue leo metus. Vitae arcu mollis blandit 
                          integer at velna
                        </p>
                      </div>
                      {/* QUESTION #6 */}
                      <div className="question wow fadeInUp" data-wow-delay="0.8s">
                        {/* Question */}
                        <h5 className="h5-sm">I have an issue with my account</h5>
                        {/* Answer */}
                        <ul className="simple-list">
                          <li className="list-item">
                            <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus</p>
                          </li>
                          <li className="list-item">
                            <p className="p-lg">Quaerat sodales sapien euismod blandit purus and ipsum primis in cubilia laoreet 
                              augue luctus
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>	{/* END QUESTIONS HOLDER */}
                </div>	{/* End row */}
              </div>	{/* END FAQs-2 QUESTIONS */}	
              {/* MORE QUESTIONS BUTTON */}	
              <div className="row">
                <div className="col-md-12">	
                  <div className="more-questions text-center mt-40">
                    <h5 className="h5-sm"><span className="flaticon-check" /> 
                      Have more questions? <a href="mailto:yourdomain@mail.com" className="skyblue-color">Ask your question here</a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>	   {/* End container */}		
          </section>	{/* END FAQs-2 */}
          {/* DOWNLOAD-1
			============================================= */}
          <section id="download-1" className="bg_sblue_img pt-100 download-section division">
            <div className="container white-color">
              <div className="row d-flex align-items-center m-row">
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6 m-bottom">
                  <div className="img-block right-column pc-25 wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/img-15.png" alt="content-image" />
                  </div>
                </div>
                {/* TEXT BLOCK */}	
                <div className="col-md-7 col-lg-6 m-top">
                  <div className="txt-block left-column pc-20 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Title */}
                    <h2 className="h2-md">Showcase your app with NordEx®</h2>
                    {/* Text */}
                    <p className="p-xl">Augue egestas volutpat egestas augue purus cubilia laoreet and magna suscipit luctus dolor tempus</p>
                    {/* STORE BADGES */}
                    <div className="stores-badge">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/appstore.png" alt="appstore-logo" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay-white" src="assets/images/googleplay.png" alt="googleplay-logo" />
                      </a>
                    </div>	
                    {/* Rating */}
                    <div className="txt-block-rating">
                      <div className="stars-rating">
                        All Versions Rating
                        <span className="flaticon-star ml-5" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />	
                        <p className="txt-rating">Based on 7.296 user reviews (App Store &amp; Google Play)</p>
                      </div>			
                    </div>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
              </div>    {/* End row */}
            </div>	   {/* End container */}	
          </section>	{/* END DOWNLOAD-1 */}
          {/* BLOG-1
			============================================= */}
          <section id="blog-1" className="wide-60 blog-section division">				
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Our Stories &amp; Latest News</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* BLOG POSTS */}
              <div className="row">
                {/* BLOG POST #1 */}
                <div id="b-post-1" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.4s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-1-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">NordEx News</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Tempor sapien donec gravida ipsum a porta justo vitae</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>12 min read</p>
                      </div>	
                    </div>
                  </div>
                </div>	{/* END  BLOG POST #1 */}
                {/* BLOG POST #2 */}
                <div id="b-post-2" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.6s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-2-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">Inspiration</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Aliquam augue impedit luctus neque purus an ipsum neque and dolor libero risus</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>8 min read</p>
                      </div>	
                    </div>	{/* END BLOG POST TEXT */}
                  </div>
                </div>	{/* END  BLOG POST #2 */}
                {/* BLOG POST #3 */}
                <div id="b-post-3" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.8s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-3-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">Tutorials</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Tempor sapien NordEx, donec gravida ipsum a porta justo</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>22 min read</p>
                      </div>	
                    </div>	{/* END BLOG POST TEXT */}
                  </div>
                </div>	{/* END  BLOG POST #3 */}
              </div>	{/* END BLOG POSTS */}
            </div>	   {/* End container */}		
          </section>	{/* END BLOG-1 */}
          {/* MORE APPS-1
			============================================= */}
          <section id="more-apps-1" className="bg_whitesmoke wide-70 moreapps-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">More Apps by DSAThemes</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* MORE APPS-1 WRAPPER */}	
              <div className="abox-1-wrapper">
                <div className="row">
                  {/* APP #1 LINK */}
                  <div className="col-md-6 wow fadeInUp" data-wow-delay="0.4s">
                    <a href="#">
                      <div className="row abox-1 d-flex align-items-center">
                        {/* App Version */}		
                        <div className="app-version"><span>2.0.1</span></div>
                        {/* Logo */}
                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
                          <img className="img-fluid" src="assets/images/app-logo-1.png" alt="app-logo" />
                        </div>
                        {/* Text */}	
                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	
                          <h5 className="h5-xs">FastChat 2</h5>
                          <p>Aliquam an augue luctus undo purus</p>
                          {/* App Rating */}
                          <div className="app-rating ico-20">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty mr-5" />	
                            4.3
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>	{/* END APP #1 LINK */}
                  {/* APP #2 LINK */}
                  <div className="col-md-6 wow fadeInUp" data-wow-delay="0.6s">
                    <a href="#">
                      <div className="row abox-1 d-flex align-items-center">
                        {/* App Version */}		
                        <div className="app-version"><span>1.3.9</span></div>
                        {/* Logo */}
                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
                          <img className="img-fluid" src="assets/images/app-logo-2.png" alt="app-logo" />
                        </div>
                        {/* Text */}	
                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	
                          <h5 className="h5-xs">MuMemos</h5>
                          <p>Aliquam an augue luctus undo purus</p>
                          {/* App Rating */}
                          <div className="app-rating ico-20">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star mr-5" />	
                            5.0
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>	{/* END APP #2 LINK */}
                  {/* APP #3 LINK */}
                  <div className="col-md-6 wow fadeInUp" data-wow-delay="0.8s">
                    <a href="#">
                      <div className="row abox-1 d-flex align-items-center">
                        {/* App Version */}		
                        <div className="app-version"><span>4.1.6</span></div>
                        {/* Logo */}
                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
                          <img className="img-fluid" src="assets/images/app-logo-4.png" alt="app-logo" />
                        </div>
                        {/* Text */}	
                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	
                          <h5 className="h5-xs">Music Player</h5>
                          <p>Aliquam an augue luctus undo purus</p>
                          {/* App Rating */}
                          <div className="app-rating ico-20">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty mr-5" />	
                            4.65
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>	{/* END APP #3 LINK */}
                  {/* APP #4 LINK */}
                  <div className="col-md-6 wow fadeInUp" data-wow-delay="1s">
                    <a href="#">
                      <div className="row abox-1 d-flex align-items-center">
                        {/* App Version */}		
                        <div className="app-version"><span>14.1.0</span></div>
                        {/* Logo */}
                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
                          <img className="img-fluid" src="assets/images/app-logo-3.png" alt="app-logo" />
                        </div>
                        {/* Text */}	
                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	
                          <h5 className="h5-xs">PowerSaver</h5>
                          <p>Aliquam an augue luctus undo purus</p>
                          {/* App Rating */}
                          <div className="app-rating ico-20">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty mr-5" />	
                            4.21
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>	{/* END APP #4 LINK */}
                  {/* APP #5 LINK */}
                  <div className="col-md-6 wow fadeInUp" data-wow-delay="1.2s">
                    <a href="#">
                      <div className="row abox-1 d-flex align-items-center">
                        {/* App Version */}		
                        <div className="app-version"><span>2.2.1</span></div>
                        {/* Logo */}
                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
                          <img className="img-fluid" src="assets/images/app-logo-5.png" alt="app-logo" />
                        </div>
                        {/* Text */}	
                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	
                          <h5 className="h5-xs">StocksHub</h5>
                          <p>Aliquam an augue luctus undo purus</p>
                          {/* App Rating */}
                          <div className="app-rating ico-20">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty mr-5" />	
                            4.45
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>	{/* END APP #5 LINK */}
                  {/* APP #6 LINK */}
                  <div className="col-md-6 wow fadeInUp" data-wow-delay="1.4s">
                    <a href="#">
                      <div className="row abox-1 d-flex align-items-center">
                        {/* App Version */}		
                        <div className="app-version"><span>2.3.5</span></div>
                        {/* Logo */}
                        <div className="col-sm-3 col-md-4 col-lg-3 abox-1-logo">	
                          <img className="img-fluid" src="assets/images/app-logo-6.png" alt="app-logo" />
                        </div>
                        {/* Text */}	
                        <div className="col-sm-9 col-md-8 col-lg-9 abox-1-txt">	
                          <h5 className="h5-xs">Equalizer 2</h5>
                          <p>Aliquam an augue luctus undo purus</p>
                          {/* App Rating */}
                          <div className="app-rating ico-20">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star mr-5" />
                            4.0
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>	{/* END APP #6 LINK */}
                </div>    {/* End row */}
              </div>	{/* END MORE APPS-1 WRAPPER */}	
            </div>	   {/* End container */}
          </section>	{/* END MORE APPS-1 */}
          
        </div>	{/* END PAGE CONTENT */}
     

            </div>
        )
    }
}
export default Home_12 