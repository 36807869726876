import React, {Component} from 'react';

class Home_5 extends React.Component {
    render() {
        return(
            <div>


        {/* PRELOADER SPINNER
		============================================= */}	
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
		============================================= */}	
        <div id="page" className="page">
         
         
          {/* HERO-5
			============================================= */}	
          <div id="hero-5" className="rel hero-section division">
            <div className="container">	
              <div className="row d-flex align-items-center">
                {/* HERO TEXT */}
                <div className="col-md-6 col-lg-4">
                  <div className="hero-5-txt wow fadeInRight" data-wow-delay="0.4s">
                    {/* Title */}
                    <h3 className="h3-lg">Simple And Beautiful Email Productivity</h3>
                    {/* Text */}
                    <p className="p-lg grey-color">Feugiat primis ligula and risus auctor a rhoncus ultrice risus in ultrice purus</p>
                    {/* HERO LINKS */}
                    <div className="hero-video-link">
                      <a href="https://www.youtube.com/embed/7e90gBu4pas" className="video-popup2 btn btn-md btn-video-link ico-30">
                        <span className="flaticon-play-button" /> Watch the Overview
                      </a>
                    </div>
                  </div>
                </div>	{/* END HERO TEXT */}
                {/* HERO IMAGE */}
                <div className="col-md-6 col-lg-4">
                  <div className="hero-5-img pc-15 text-center">				
                    <img className="img-fluid" src="assets/images/hero-5-img.png" alt="hero-image" />
                  </div>
                </div>
                {/* HERO-5 BUTTONS */}
                <div className="col-md-6 col-lg-4">
                  <div className="hero-5-btns wow fadeInLeft" data-wow-delay="0.4s">
                    {/* Title */}
                    <h3 className="h3-lg">Make Choice And Have Fun!</h3>
                    {/* STORE BADGES */}												
                    <div className="stores-badge">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/appstore.png" alt="appstore-badge" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay" src="assets/images/googleplay.png" alt="googleplay-badge" />
                      </a> 
                      {/* Aamazon Market 
									<a href="#" class="store">
										<img class="amazon" src="assets/images/amazon.png" alt="amazon-badge" />
									</a>  */}
                      {/* Mac AppStore 
									<a href="#" class="store">
										<img class="mac-appstore" src="assets/images/macstore.png" alt="macstore-badge" />
									</a> */} 
                      {/* Microsoft Store  
									<a href="#" class="store">
										<img class="microsoft" src="assets/images/microsoft.png" alt="microsoft-badge" />
									</a> */} 
                    </div>	{/* END STORE BADGES */}
                    {/* Rating */}
                    <div className="txt-block-rating">
                      <div className="stars-rating grey-color">
                        All Versions Rating
                        <span className="flaticon-star ml-5" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star" />
                        <span className="flaticon-star-half-empty" />	
                        <p className="txt-rating">Based on 7.296 user reviews</p>
                      </div>			
                    </div>
                  </div>
                </div>	{/* END HERO-5 BUTTONS */}
              </div>	  {/* End row */}
            </div>	  {/* End container */}
          </div>	  {/* END HERO-5 */}
          {/* FEATURES-1
			============================================= */}
          <section id="features-1" className="wide-60 features-section division">
            <div className="container">
              {/* FEATURES-1 WRAPPER */}	
              <div className="fbox-1-wrapper pc-30 text-center">
                <div className="row">
                  {/* FEATURE BOX #1 */}
                  <div id="fb-1-1" className="col-md-4">
                    <div className="fbox-1 mb-40 wow fadeInUp" data-wow-delay="0.4s">
                      {/* Icon */}
                      <div className="fbox-1-ico ico-80 stateblue-color"><span className="flaticon-mobile-phone" /></div>
                      {/* Title */}
                      <h5 className="h5-sm">Quick Access</h5>
                      {/* Text */}
                      <p className="p-lg">Porta semper lacus a cursus, feugiat primis ultrice ligula and risus auctor ultrice and 
                        ociis ligula blandit
                      </p>
                    </div>
                  </div>
                  {/* FEATURE BOX #2 */}
                  <div id="fb-1-2" className="col-md-4">
                    <div className="fbox-1 mb-40 wow fadeInUp" data-wow-delay="0.6s">
                      {/* Icon */}
                      <div className="fbox-1-ico ico-80 stateblue-color"><span className="flaticon-chat-4" /></div>
                      {/* Title */}
                      <h5 className="h5-sm">Smart Notifications</h5>
                      {/* Text */}
                      <p className="p-lg">Porta semper lacus a cursus, feugiat primis ultrice ligula and risus auctor ultrice and 
                        ociis ligula blandit
                      </p>
                    </div>
                  </div>
                  {/* FEATURE BOX #3 */}
                  <div id="fb-1-3" className="col-md-4">
                    <div className="fbox-1 mb-40 wow fadeInUp" data-wow-delay="0.8s">
                      {/* Icon */}
                      <div className="fbox-1-ico ico-80 stateblue-color"><span className="flaticon-spam" /></div>
                      {/* Title */}
                      <h5 className="h5-sm">Spam Protection</h5>
                      {/* Text */}
                      <p className="p-lg">Porta semper lacus a cursus, feugiat primis ultrice ligula and risus auctor ultrice and 
                        ociis ligula blandit
                      </p>
                    </div>
                  </div>	
                </div>  {/* End row */}	
              </div>	{/* END FEATURES-1 WRAPPER */}	
            </div>	   {/* End container */}		
          </section>	{/* END FEATURES-1 */}
          {/* CONTENT-3
			============================================= */}
          <section id="content-3" className="pb-60 content-section division">
            <div className="container">
              {/* CONTENT BOX-1 */}
              <div id="cb-1-1" className="cbox-1 pb-15">
                <div className="row d-flex align-items-center m-row">
                  {/* TEXT BLOCK */}	
                  <div className="col-md-7 col-lg-6 m-bottom">
                    <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      {/* Section ID */}	
                      <span className="section-id grey-color">Unlimited Access</span>
                      {/* Title */}	
                      <h2 className="h2-md">Stay connected with friends easily</h2>
                      {/* Text */}	
                      <p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue 
                        luctus magna dolor luctus at egestas sapien vitae. Nemo egestas volute and turpis dolores aliquam quaerat 
                        sodales a sapien
                      </p>
                      {/* Text */}	
                      <p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue 
                        luctus magna dolor luctus and egestas sapien egestas vitae nemo volute
                      </p>
                    </div>
                  </div>	{/* END TEXT BLOCK */}	
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6 m-top">
                    <div className="img-block right-column pc-15 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img-07.png" alt="content-image" />
                    </div>
                  </div>
                </div>  {/* End row */}	
              </div>	{/* END CONTENT BOX-1 */}
              {/* CONTENT BOX-2 */}
              <div id="cb-1-2" className="cbox-1">
                <div className="row d-flex align-items-center">
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6">
                    <div className="img-block left-column pc-25 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img-02.png" alt="content-image" />
                    </div>
                  </div>
                  {/* TEXT BLOCK */}	
                  <div className="col-md-7 col-lg-6">
                    <div className="txt-block right-column pc-30 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      {/* Section ID */}	
                      <span className="section-id grey-color">Perfect Integration</span>
                      {/* Title */}	
                      <h2 className="h2-md">Lightning fast and super powerful</h2>
                      {/* Text */}	
                      <p className="p-lg">Quaerat sodales sapien euismod purus blandit a purus ipsum primis in cubilia laoreet augue 
                        luctus magna dolor luctus at egestas sapien vitae nemo egestas volute and turpis dolores aliquam quaerat 
                        sodales a sapien
                      </p>
                    </div>
                  </div>	{/* END TEXT BLOCK */}	
                </div>	  {/* End row */}	
              </div>	{/* END CONTENT BOX-2 */}
            </div>     {/* End container */}
          </section>	{/* END CONTENT-3 */}	
          {/* FEATURES-11
			============================================= */}
          <section id="features-11" className="bg_whitesmoke wide-100 features-section">
            {/* SECTION TITLE */}	
            <div className="container">
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Ready to Try NordEx?</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
            </div>
            {/* FEATURES-10 WRAPPER */}
            <div className="container">
              <div className="bg_white wide-50 fbox-11-wrapper division">
                <div className="row">
                  {/* FEATURE BOX #1 */}
                  <div id="fb-11-1" className="col-lg-4">
                    <div className="fbox-11 mb-40 wow fadeInUp" data-wow-delay="0.4s">
                      {/* Icon */}
                      <div className="fbox-11-ico ico-60 bg_slateblue white-color"><span className="flaticon-user-1" /></div>
                      {/* Title */}
                      <h5 className="h5-md">Multiple Accounts</h5>
                      {/* Text */}
                      <p className="p-lg">Porta semper lacus a cursus, feugiat primis ultrice ligula and risus auctor ultrice and 
                        ociis ligula blandit
                      </p>
                    </div>
                  </div>
                  {/* FEATURE BOX #2 */}
                  <div id="fb-11-2" className="col-lg-4">
                    <div className="fbox-11 mb-40 wow fadeInUp" data-wow-delay="0.6s">
                      {/* Icon */}
                      <div className="fbox-11-ico ico-60 bg_slateblue white-color"><span className="flaticon-smiley" /></div>
                      {/* Title */}
                      <h5 className="h5-md">Stickers &amp; Emojis</h5>
                      {/* Text */}
                      <p className="p-lg">Porta semper lacus a cursus, feugiat primis ultrice ligula and risus auctor ultrice and 
                        ociis ligula blandit
                      </p>
                    </div>
                  </div>
                  {/* FEATURE BOX #3 */}
                  <div id="fb-11-3" className="col-lg-4">
                    <div className="fbox-11 mb-40 wow fadeInUp" data-wow-delay="0.8s">
                      {/* Icon */}
                      <div className="fbox-11-ico ico-60 bg_slateblue white-color"><span className="flaticon-image" /></div>
                      {/* Title */}
                      <h5 className="h5-md">Share Files &amp; Media</h5>
                      {/* Text */}
                      <p className="p-lg">Porta semper lacus a cursus, feugiat primis ultrice ligula and risus auctor ultrice and 
                        ociis ligula blandit
                      </p>
                    </div>
                  </div>	
                </div>  {/* End row */}
              </div>   {/* End container */}
            </div>	{/* END FEATURES-11 WRAPPER */}
          </section>	{/* END FEATURES-11 */}
          {/* CONTENT-5
			============================================= */}
          <section id="content-5" className="pb-80 content-section">
            <div className="bg-inner bg_whitesmoke division">
              <div className="container">
                <div className="row d-flex align-items-center">
                  {/* IMAGE BLOCK */}
                  <div className="col-md-5 col-lg-6">
                    <div className="content-5-img right-column pc-20 text-center wow fadeInRight" data-wow-delay="0.6s">
                      <img className="img-fluid" src="assets/images/img-04.png" alt="content-image" />
                    </div>
                  </div>
                  {/* TEXT BLOCK */}
                  <div className="col-md-7 col-lg-6">
                    <div className="txt-block left-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                      {/* Section ID */}	
                      <span className="section-id grey-color">Fastest Messaging</span>
                      {/* Title */}	
                      <h2 className="h2-md">Send text, voice, picture messages more faster</h2>
                      {/* Text */}
                      <p className="p-lg">Aliqum mullam blandit and tempor sapien donec ipsum gravida porta. Velna vitae auctor 
                        congue magna impedit ligula risus. Mauris donec ligula and magnis undo sapien sagittis sapien pretium 
                        enim gravida purus ligula
                      </p>
                    </div>	
                  </div>	{/* END CONTENT TXT */}	
                </div>	  {/* End row */}
              </div>	   {/* End container */}
            </div>		{/* End Inner Background */}	
          </section>	{/* END CONTENT-5 */}
          {/* CONTENT-1
			============================================= */}
          <section id="content-1" className="wide-60 content-section division">
            <div className="container rel">
              <div className="row d-flex align-items-center m-row">
                {/* TEXT BLOCK */}	
                <div className="col-md-7 col-lg-6 m-bottom">
                  <div className="txt-block left-column mb-40 wow fadeInRight" data-wow-delay="0.6s">
                    {/* CONTENT BOX #1 */}
                    <div className="cbox mb-30">
                      {/* Icon */}
                      <div className="cbox-ico ico-65 stateblue-color"><span className="flaticon-video-player" /></div> 
                      {/* Text */}
                      <div className="cbox-txt">
                        <h5 className="h5-sm">Text, Voice &amp; Video Calls</h5>
                        <p className="p-lg">Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at
                          suscipit quaerat ultrice tellus viverra
                        </p>
                      </div>
                    </div>	
                    {/* CONTENT BOX #2 */}
                    <div className="cbox mb-30">
                      {/* Icon */}
                      <div className="cbox-ico ico-65 stateblue-color"><span className="flaticon-smiley" /></div> 
                      {/* Text */}
                      <div className="cbox-txt">
                        <h5 className="h5-sm">Stickers, Emojis, Themes</h5>
                        <p className="p-lg">Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at
                          suscipit quaerat ultrice tellus viverra
                        </p>
                      </div>
                    </div>	
                    {/* CONTENT BOX #3 */}
                    <div className="cbox">
                      {/* Icon */}
                      <div className="cbox-ico ico-65 stateblue-color"><span className="flaticon-image" /></div> 
                      {/* Text */}
                      <div className="cbox-txt">
                        <h5 className="h5-sm">Share Files &amp; Media</h5>
                        <p className="p-lg">Ligula risus auctor tempus dolor feugiat undo lacinia purus lipsum primis potenti at
                          suscipit quaerat ultrice tellus viverra
                        </p>
                      </div>
                    </div>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6 m-top">
                  <div className="img-block right-column pc-20 mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/img-05.png" alt="content-image" />
                  </div>
                </div>
              </div>	   {/* End row */}
              {/* Transparent Background Elements */}
              <div className="tra-bkg-wrapper">
                <div className="bg_el_01 wow fadeInRight" data-wow-delay="1s" />
                <div className="bg_el_04 wow fadeInLeft" data-wow-delay="0.8s" />
              </div>
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-1 */}
          {/* FEATURES-4
			============================================= */}
          <section id="features-4" className="pb-100 features-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">More Features to Expect</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* FEATURES-4 WRAPPER */}	
              <div className="fbox-4-wrapper">
                <div className="row">
                  {/* FEATURE BOX #1 */}
                  <div id="fb-4-1" className="col-md-6">
                    <div className="fbox-4 bg_whitesmoke mb-30 wow fadeInUp" data-wow-delay="0.4s">
                      <div className="row d-flex align-items-center m-row">
                        {/* TEXT */}
                        <div className="col-lg-8 m-bottom">
                          <div className="fbox-4-txt">
                            {/* Title */}
                            <h5 className="h5-sm">Dark &amp; Light Modes</h5>
                            {/* Text */}
                            <p className="p-md">Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna 
                              at vitae congue tempus
                            </p>
                          </div>
                        </div>
                        {/* ICON */}
                        <div className="col-lg-4 m-top">
                          <div className="fbox-4-ico ico-100 grey-color text-center">
                            <span className="flaticon-paint-brush" />
                          </div>
                        </div>
                      </div>	{/* End row */}
                    </div>  {/* End fbox-4 */}
                  </div>	{/* END FEATURE BOX #1 */}
                  {/* FEATURE BOX #2 */}
                  <div id="fb-4-2" className="col-md-6">
                    <div className="fbox-4 bg_whitesmoke mb-30 wow fadeInUp" data-wow-delay="0.6s">
                      <div className="row d-flex align-items-center m-row">
                        {/* TEXT */}
                        <div className="col-lg-8 m-bottom">
                          <div className="fbox-4-txt">
                            {/* Title */}
                            <h5 className="h5-sm">Group Messaging</h5>
                            {/* Text */}
                            <p className="p-md">Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna 
                              at vitae congue tempus
                            </p>
                          </div>
                        </div>
                        {/* ICON */}
                        <div className="col-lg-4 m-top">
                          <div className="fbox-4-ico ico-100 stateblue-color text-center">
                            <span className="flaticon-smartphone-8" />
                          </div>
                        </div>
                      </div>	{/* End row */}
                    </div>  {/* End fbox-4 */}
                  </div>	{/* END FEATURE BOX #2 */}
                  {/* FEATURE BOX #3 */}
                  <div id="fb-4-3" className="col-md-6">
                    <div className="fbox-4 bg_whitesmoke wow fadeInUp" data-wow-delay="0.8s">
                      <div className="row d-flex align-items-center m-row">
                        {/* TEXT */}
                        <div className="col-lg-8 m-bottom">
                          <div className="fbox-4-txt">
                            {/* Title */}
                            <h5 className="h5-sm">Strong Encryption</h5>
                            {/* Text */}
                            <p className="p-md">Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna 
                              at vitae congue tempus
                            </p>
                          </div>
                        </div>
                        {/* ICON */}
                        <div className="col-lg-4 m-top">
                          <div className="fbox-4-ico ico-100 stateblue-color text-center">
                            <span className="flaticon-mobile-1" />
                          </div>
                        </div>
                      </div>	{/* End row */}
                    </div>  {/* End fbox-4 */}
                  </div>	{/* END FEATURE BOX #3 */}
                  {/* FEATURE BOX #4 */}
                  <div id="fb-4-4" className="col-md-6">
                    <div className="fbox-4 bg_whitesmoke wow fadeInUp" data-wow-delay="1s">
                      <div className="row d-flex align-items-center m-row">
                        {/* TEXT */}
                        <div className="col-lg-8 m-bottom">
                          <div className="fbox-4-txt">
                            {/* Title */}
                            <h5 className="h5-sm">Voice Assistant</h5>
                            {/* Text */}
                            <p className="p-md">Porta semper lacus cursus risus and feugiat primis undo sodales a sapien magna 
                              at vitae congue tempus
                            </p>
                          </div>
                        </div>
                        {/* ICON */}
                        <div className="col-lg-4 m-top">
                          <div className="fbox-4-ico ico-100 grey-color text-center">
                            <span className="flaticon-voice-message-1" />
                          </div>
                        </div>
                      </div>	{/* End row */}
                    </div>  {/* End fbox-4 */}
                  </div>	{/* END FEATURE BOX #4 */}
                </div>  {/* End row */}	
              </div>	{/* END FEATURES-4 WRAPPER */}	
            </div>	   {/* End container */}		
          </section>	{/* END FEATURES-4 */}
          {/* STATISTIC-2
			============================================= */}
          <div id="statistic-2" className="pt-70 pb-30 bg_purple_img statistic-section division">
            <div className="container white-color">
              {/* STATISTIC-2 WRAPPER */}
              <div className="statistic-2-wrapper pc-35 text-center">
                <div className="row">
                  {/* STATISTIC BLOCK #1 */}
                  <div id="sb-2-1" className="col-sm-6 col-lg-3">							
                    <div className="statistic-block mb-40 wow fadeInUp" data-wow-delay="0.4s">	
                      {/* Digit */}
                      <h2 className="h2-title-sm statistic-number"><span className="count-element">28</span>%</h2>
                      {/* Text */}
                      <h5 className="h5-xs">Faster Access</h5>
                    </div>								
                  </div>
                  {/* STATISTIC BLOCK #2 */}
                  <div id="sb-2-2" className="col-sm-6 col-lg-3">										
                    <div className="statistic-block mb-40 wow fadeInUp" data-wow-delay="0.6s">	
                      {/* Digit */}
                      <h2 className="h2-title-sm statistic-number"><span className="count-element">47</span>%</h2>
                      {/* Text */}
                      <h5 className="h5-xs">App Productivity</h5>
                    </div>							
                  </div>
                  {/* STATISTIC BLOCK #3 */}
                  <div id="sb-2-3" className="col-sm-6 col-lg-3">							
                    <div className="statistic-block mb-40 wow fadeInUp" data-wow-delay="0.8s">
                      {/* Digit */}
                      <h2 className="h2-title-sm statistic-number"><span className="count-element">54</span>%</h2>
                      {/* Text */}
                      <h5 className="h5-xs">Secure Access</h5>	
                    </div>						
                  </div>
                  {/* STATISTIC BLOCK #4 */}
                  <div id="sb-2-4" className="col-sm-6 col-lg-3">							
                    <div className="statistic-block mb-40 wow fadeInUp" data-wow-delay="1s">		
                      {/* Digit */}
                      <h2 className="h2-title-sm statistic-number"><span className="count-element">36</span>%</h2>
                      {/* Text */}	
                      <h5 className="h5-xs">Battery Saving</h5>	
                    </div>						
                  </div>
                </div>	{/* End row */}
              </div>	{/* END STATISTIC-2 WRAPPER */}
            </div>	 {/* End container */}		
          </div>	 {/* END STATISTIC-2 */}
          {/* CONTENT-2
			============================================= */}
          <section id="content-2" className="wide-50 content-section division">
            <div className="container">
              <div className="row d-flex align-items-center">
                {/* IMAGE BLOCK */}
                <div className="col-md-5 col-lg-6">
                  <div className="img-block left-column pc-20 mb-40 wow fadeInRight" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/img-03.png" alt="content-image" />
                  </div>
                </div>
                {/* TEXT BLOCK */}	
                <div className="col-md-7 col-lg-6">
                  <div className="txt-block right-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id grey-color">Beauty of Simplicity</span>
                    {/* Title */}	
                    <h2 className="h2-md">Beautiful, award-winning design</h2>
                    {/* List */}	
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium 
                          ligula rutrum tempor sapien
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo 
                          pretium a purus mauris
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>	{/* END TEXT BLOCK */}	
              </div>	   {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-2 */}
          {/* CONTENT-13
			============================================= */}
          <section id="content-13" className="pb-60 content-section division">
            <div className="container">
              <div className="row d-flex align-items-center">
                {/* TEXT BLOCK */}		
                <div className="col-md-6 col-xl-6">
                  <div className="txt-block left-column mb-40 wow fadeInLeft" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id">Totally Optimized</span>
                    {/* Title */}	
                    <h2 className="h2-md">Intuitive features, powerful results</h2>
                    {/* List */}	
                    <ul className="simple-list">
                      <li className="list-item">
                        <p className="p-lg">Fringilla risus, luctus mauris orci auctor purus euismod pretium purus pretium 
                          ligula rutrum tempor sapien
                        </p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Quaerat sodales sapien euismod purus blandit</p>
                      </li>
                      <li className="list-item">
                        <p className="p-lg">Nemo ipsam egestas volute turpis dolores ut aliquam quaerat sodales sapien undo 
                          pretium a purus mauris
                        </p>
                      </li>
                    </ul>
                    {/* Button */}	
                    <a href="#faqs-1" className="btn btn-tra-grey rose-hover">Read The FAQs</a>
                  </div>	
                </div>	{/* END TEXT BLOCK */}		
                {/* IMAGE BLOCK */}	
                <div className="col-md-6 col-xl-6">
                  <div className="content-13-img wow fadeInRight" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/tablet.png" alt="content-image" />
                  </div>	
                </div>
              </div>	  {/* End row */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-13 */}
          {/* BRANDS-1
			============================================= */}
          <div id="brands-1" className="bg_whitesmoke brands-section division">
            <div className="container">					
              <div className="row">
                <div className="col text-center pc-25">	
                  <div className="owl-carousel brands-carousel">
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-1.png" alt="brand-logo" />
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-2.png" alt="brand-logo" />
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-3.png" alt="brand-logo" />
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-4.png" alt="brand-logo" />
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-5.png" alt="brand-logo" />
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-6.png" alt="brand-logo" />
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-7.png" alt="brand-logo" />
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-8.png" alt="brand-logo" />
                    </div>
                    {/* BRAND LOGO IMAGE */}
                    <div className="brand-logo">
                      <img className="img-fluid" src="assets/images/brand-9.png" alt="brand-logo" />
                    </div>
                  </div>
                </div>
              </div>      {/* End row */}
            </div>	    {/* End container */}
          </div>	{/* END BRANDS-1 */}
          {/* CONTENT-11
			============================================= */}
          <section id="content-11" className="wide-100 content-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-60">		
                    {/* Title */}	
                    <h2 className="h2-md">Accessible for All Platforms</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* IMAGE BLOCK */}
              <div className="row">
                <div className="col-md-12">
                  <div className="img-block text-center wow fadeInUp" data-wow-delay="0.6s">
                    <img className="img-fluid" src="assets/images/browsers.png" alt="content-image" />
                  </div>
                </div>
              </div>
              {/* DOWNLOAD BUTTON */}		
              <div className="row">
                <div className="col-md-12">
                  <div className="content-11-btn ico-20 ico-right mt-60 wow fadeInUp" data-wow-delay="0.6s">
                    {/* Button */}
                    <a href="https://www.youtube.com/watch?v=7e90gBu4pas" className="video-popup2 btn btn-md btn-tra-grey rose-hover">
                      See NordEx in Action <span className="flaticon-play-button" />
                    </a> 
                    {/* OS Prerequisite */}
                    <span className="os-version">Available on Android, iOS and macOS</span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}
            </div>	   {/* End container */}
          </section>	{/* END CONTENT-11 */}
          {/* DOWNLOAD-6
			============================================= */}
          <section id="download-6" className="bg_chat wide-100 download-section division">
            <div className="container white-color">
              <div className="row">	
                {/* DOWNLOAD TXT */}	
                <div className="col-lg-6">	
                  <div className="download-6-txt pl-20 wow fadeInUp" data-wow-delay="0.6s">
                    {/* Section ID */}	
                    <span className="section-id">Built For Speed</span>
                    {/* Title 	*/}	
                    <h2 className="h2-md">Share text, voice, photos, videos, GIFs and files for free</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque at purus ipsum neque dolor primis libero tempus, blandit 
                      posuere orci auctor purus euismod an aliquam quaerat purus
                    </p>
                    {/* STORE BADGES */}												
                    <div className="stores-badge">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/appstore.png" alt="appstore-badge" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay" src="assets/images/googleplay.png" alt="googleplay-badge" />
                      </a> 
                      {/* Aamazon Market 
									<a href="#" class="store">
										<img class="amazon" src="assets/images/amazon.png" alt="amazon-badge" />
									</a>  */}
                      {/* Mac AppStore 
									<a href="#" class="store">
										<img class="mac-appstore" src="assets/images/macstore.png" alt="macstore-badge" />
									</a> */} 
                      {/* Microsoft Store  
									<a href="#" class="store">
										<img class="microsoft" src="assets/images/microsoft.png" alt="microsoft-badge" />
									</a> */} 
                    </div>	{/* END STORE BADGES */}	
                  </div>
                </div>	{/* END DOWNLOAD TXT */}	
              </div>    {/* End row */}
            </div>	   {/* End container */}	
          </section>	{/* END  DOWNLOAD-6 */}
          {/* PRICING-2
			============================================= */}
          <section id="pricing-2" className="wide-100 pricing-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Simple and Flexible Pricing</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* PRICING TABLES */}
              <div className="row pricing-row">
                {/* FREE PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.4s">	
                    {/* Plan Price  */}
                    <div className="pricing-plan bg_white">
                      <h5 className="h5-xs">NordEx Free</h5>									
                      <sup className="dark-color">$</sup>								
                      <span className="dark-color">0</span>
                      <sup className="validity dark-color"><span>.00</span> / month</sup>
                      <p className="p-sm">The price per one user. Change or cancel your plan anytime</p>
                      {/* Pricing Table Button  */}
                      <a href="#" className="btn btn-tra-grey rose-hover">Download Now</a>
                    </div>	
                  </div>
                </div>	{/* END FREE PLAN */}
                {/* MONTHLY PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.6s">	
                    {/* Plan Price  */}
                    <div className="pricing-plan bg_white">
                      <h5 className="h5-xs">Monthly Billing</h5>	
                      <sup className="dark-color">$</sup>								
                      <span className="dark-color">6</span>
                      <sup className="validity dark-color"><span>.25</span> / month</sup>
                      <p className="p-sm">The price per one user. Change or cancel your plan anytime</p>
                      {/* Pricing Table Button */}
                      <a href="#" className="btn btn-tra-grey rose-hover">Select Plan</a>
                    </div>	
                  </div>
                </div>	{/* END MONTHLY PLAN  */}
                {/* ANNUAL PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table rel mb-40 wow fadeInUp" data-wow-delay="0.8s">
                    {/* Hightlight Badge */}
                    <div className="badge-wrapper">
                      <div className="highlight-badge bg_slateblue white-color">
                        <h6 className="h6-sm">Save up to 25%</h6>
                      </div>
                    </div>	
                    {/* Plan Price  */}
                    <div className="pricing-plan highlight bg_whitesmoke">
                      <h5 className="h5-xs">Annual Billing</h5>	
                      <sup className="dark-color">$</sup>								
                      <span className="dark-color">5</span>
                      <sup className="validity dark-color"><span>.69</span> / month</sup>
                      <p className="p-sm">The price per one user. Change or cancel your plan anytime</p>
                      {/* Pricing Table Button */}
                      <a href="#" className="btn btn-tra-grey rose-hover">Select Plan</a>
                    </div>	
                  </div>
                </div>	{/* END ANNUAL PLAN */}
              </div>	{/* END PRICING TABLES */}
              {/* DOWNLOAD BUTTON */}		
              <div className="row">
                <div className="col-md-12">
                  <div className="pricing-2-download-btn mt-20 text-center wow fadeInUp" data-wow-delay="0.8s">
                    {/* Button */}
                    <a href="pricing.html" className="btn btn-md btn-tra-grey rose-hover mb-10">Start Free 14-day Trial</a>
                    {/* OS Prerequisite */}
                    <span className="os-version">Request OS X 10.10 or later</span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}	
            </div>	   {/* End container */}
          </section>	{/* END PRICING-2 */}
          {/* TESTIMONIALS-4
			============================================= */}
          <section id="reviews-4" className="bg_aliceblue wide-60 reviews-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-60">		
                    {/* Title */}	
                    <h2 className="h2-md">Why Customers Love Us</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* TESTIMONIALS-4 WRAPPER */}
              <div className="reviews-4-wrapper">
                <div className="row">
                  {/* TESTIMONIALS LEFT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #1 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="0.4s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Etiam sapien sem at sagittis congue an augue massa varius an egestas suscipit magna a tempus aliquet 
                            blandit <span className="txt-highlight">tempor sapien gravida donec</span> ipsum porta	 			   
                          </p>
                        </div>	
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">John Sweet</h5>
                          <p>Mac Store Review, USA</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #1 */}
                      {/* TESTIMONIAL #2 */}
                      <div className="review-4 wow fadeInUp" data-wow-delay="1s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>An augue cubilia laoreet undo magna a suscipit egestas magna ipsum at ligula vitae 
                            <span className="txt-highlight">purus ipsum primis</span> cubilia
                          </p>
                        </div>
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* App Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star-half-empty" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">Mark Hodges</h5>
                          <p>Marketing Manager, Company</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #2 */}
                    </div>
                  </div>	{/* END TESTIMONIALS LEFT COLUMN */}
                  {/* TESTIMONIALS RIGHT COLUMN */}
                  <div className="col-lg-6">
                    <div className="reviews-4-column">
                      {/* TESTIMONIAL #3 */}
                      <div className="review-4 mt-25 wow fadeInUp" data-wow-delay="0.8s">
                        {/* Testimonial Text */}
                        <div className="review-4-txt">
                          <p>Mauris donec ociis magnis undo sapien etiam sapien congue augue pretium and ligula augue lectus 
                            aenean  ociis magna donec and magnis sapien sagittis ipsum <span className="txt-highlight">sapien congue tempor gravida </span> a donec ipsum porta justo integer a velna mollis laoreet vitae mauris undo 
                            tortor tempor sapien gravida donec ipsum porta
                          </p>
                        </div>	
                        {/* Testimonial Data */}
                        <div className="review-4-data">
                          {/* App Rating */}
                          <div className="app-rating ico-20 yellow-color">
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                            <span className="flaticon-star" />
                          </div>
                          {/* Author */}
                          <h5 className="h5-sm">Dominic Johnson</h5>
                          <p>Chief Strategist, Company</p>	
                        </div>
                      </div>	{/* END TESTIMONIAL #3 */}
                    </div>
                  </div>	{/* END TESTIMONIALS RIGHT COLUMN */}
                </div>  {/* End row */}
              </div>	{/* END TESTIMONIALS-4 WRAPPER */}
              {/* DOWNLOAD BUTTON */}		
              <div className="row">
                <div className="col-md-12">
                  <div className="reviews-download-btn wow fadeInUp" data-wow-delay="1s">
                    {/* STORE BADGES */}												
                    <div className="stores-badge mb-10">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/appstore.png" alt="appstore-badge" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay" src="assets/images/googleplay.png" alt="googleplay-badge" />
                      </a> 
                      {/* Aamazon Market 
									<a href="#" class="store">
										<img class="amazon" src="assets/images/amazon.png" alt="amazon-badge" />
									</a>  */}
                      {/* Mac AppStore 
									<a href="#" class="store">
										<img class="mac-appstore" src="assets/images/macstore.png" alt="macstore-badge" />
									</a> */} 
                      {/* Microsoft Store  
									<a href="#" class="store">
										<img class="microsoft" src="assets/images/microsoft.png" alt="microsoft-badge" />
									</a> */} 
                    </div>	{/* END STORE BADGES */}
                    {/* OS Prerequisite */}
                    <span className="os-version">Available for iOS 8 and Android Devices From 5.5</span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}	
            </div>     {/* End container */}
          </section>	{/* END TESTIMONIALS-4 */}
          {/* FAQs-1
			============================================= */}
          <section id="faqs-1" className="faqs-section division">				
            <div className="container">
              <div className="row">
                {/* FAQs TITLE */}
                <div className="col-lg-4">
                  <div className="faqs-1-title pc-10">
                    {/* Section ID */}	
                    <span className="section-id grey-color">FAQs</span>
                    {/* Title */}
                    <h2 className="h2-md mb-20">Common Questions</h2>
                    {/* Text */}	
                    <p className="p-lg">Aliquam augue suscipit luctus neque purus ipsum neque dolor primis undo tempus, blandit and 
                      cursus varius
                    </p>
                  </div>
                </div>
                {/* QUESTIONS ACCORDION */}
                <div className="col-lg-8">
                  <div id="accordion" role="tablist" className="pc-20">
                    {/* QUESTION #1 */}
                    <div className="card grey-color">
                      {/* Question */}
                      <div className="card-header" role="tab" id="headingOne">
                        <h5 className="h5-sm">
                          <a data-toggle="collapse" href="#collapseOne" role="button" aria-expanded="true" aria-controls="collapseOne">
                            Can I see NordEx in action before purchasing it?
                          </a>
                        </h5>
                      </div>
                      {/* Answer */}
                      <div id="collapseOne" className="collapse show" role="tabpanel" aria-labelledby="headingOne" data-parent="#accordion">
                        <div className="card-body">
                          {/* Text */}	
                          <p className="p-lg">Maecenas gravida porttitor quis vehicula magna luctus tempor. Quisque vel laoreet 
                            turpis. Viverra urna augue, a augue dictum tempor. Sed pulvinar nibh consectetur varius viverra. 
                            Laoreet augue ac massa lorem nulla
                          </p>	
                        </div>
                      </div>
                    </div>	{/* END QUESTION #1 */}
                    {/* QUESTION #2 */}
                    <div className="card grey-color">
                      {/* Question */}
                      <div className="card-header" role="tab" id="headingTwo">
                        <h5 className="h5-sm">
                          <a className="collapsed" data-toggle="collapse" href="#collapseTwo" role="button" aria-expanded="false" aria-controls="collapseTwo">
                            I’ve got iPhone 5. Is NordEx compatible with it?
                          </a>
                        </h5>
                      </div>
                      {/* Answer */}
                      <div id="collapseTwo" className="collapse" role="tabpanel" aria-labelledby="headingTwo" data-parent="#accordion">
                        <div className="card-body">
                          {/* Text */}	
                          <p className="p-lg">Sagittis congue augue egestas volutpat egestas magna suscipit egestas magna ipsum 
                            vitae purus an efficitur ipsum primis in cubilia laoreet augue egestas luctus donec curabitur 
                            dapibus libero tempor
                          </p>
                        </div>
                      </div>
                    </div>	{/* END QUESTION #2 */}
                    {/* QUESTION #3 */}
                    <div className="card grey-color">
                      {/* Question */}
                      <div className="card-header" role="tab" id="headingThree">
                        <h5 className="h5-sm">
                          <a className="collapsed" data-toggle="collapse" href="#collapseThree" role="button" aria-expanded="false" aria-controls="collapseThree">
                            What are the requirements for using NordEx?
                          </a>
                        </h5>
                      </div>
                      {/* Answer */}
                      <div id="collapseThree" className="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordion">
                        <div className="card-body">
                          {/* Text */}	
                          <p className="p-lg">Sagittis congue augue egestas volutpat egestas magna suscipit egestas and magna 
                            ipsum vitae purus and efficitur ipsum primis in cubilia laoreet
                          </p>
                          {/* Text */}	
                          <p className="p-lg">Sapien egestas, congue gestas posuere cubilia congue ipsum mauris lectus laoreet 
                            gestas neque vitae auctor eros dolor luctus placerat a magna cursus congue magna nihil mpedit 
                            ligula sem congue tempor gravida
                          </p>
                        </div>
                      </div>
                    </div>	  {/* END QUESTION #3 */}
                    {/* QUESTION #4 */}
                    <div className="card grey-color">
                      {/* Question */}
                      <div className="card-header" role="tab" id="headingFour">
                        <h5 className="h5-sm">
                          <a className="collapsed" data-toggle="collapse" href="#collapseFour" role="button" aria-expanded="false" aria-controls="collapseFour">   		
                            How does NordEx handle my privacy?
                          </a>
                        </h5>
                      </div>
                      {/* Answer */}
                      <div id="collapseFour" className="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordion">
                        <div className="card-body">
                          {/* Text */}
                          <p className="p-lg">An augue cubilia laoreet and magna suscipit egestas magna ipsum purus ipsum primis 
                            and augue ultrice ligula egestas suscipit lectus gestas integer congue a lectus porta tristique phasellus neque blandit and tristique
                          </p> 
                        </div>
                      </div>
                    </div>	  {/* END QUESTION #4 */}
                    {/* QUESTION #5 */}
                    <div className="card last-card grey-color">
                      {/* Question */}
                      <div className="card-header" role="tab" id="headingFive">
                        <h5 className="h5-sm">
                          <a className="collapsed" data-toggle="collapse" href="#collapseFive" role="button" aria-expanded="false" aria-controls="collapseFive">   		
                            How can I cancel my account?
                          </a>
                        </h5>
                      </div>
                      {/* Answer */}
                      <div id="collapseFive" className="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordion">
                        <div className="card-body">
                          {/* Text */}
                          <p className="p-lg">Curabitur ac dapibus libero. Quisque eu congue tristique neque. Phasellus blandit 
                            tristique justo undo aliquam. Aliquam vitae molestie nunc. Quisque sapien justo, aliquet non 
                            molestie sed purus, venenatis sem tempor
                          </p> 
                        </div>
                      </div>
                    </div>	  {/* END QUESTION #5 */}
                  </div>	{/* END ACCORDION */}
                  {/* MORE QUESTIONS BUTTON */}		
                  <div className="more-questions pc-20 mt-35">
                    <h5 className="h5-sm"><span className="flaticon-check" /> 
                      Have more questions? <a href="mailto:yourdomain@mail.com" className="violet-color">Ask your question here</a>
                    </h5>
                  </div>
                </div>  {/* End col-x */}	
              </div>	{/* End row */}
            </div>	   {/* End container */}	
          </section>	{/* END FAQs-1 */}	
          {/* NEWSLETTER-1
			============================================= */}
          <section id="newsletter-1" className="pb-20 newsletter-section division">
            <div className="container">
              <div className="newsletter-wrapper bg_white">
                <div className="row d-flex align-items-center">
                  {/* SECTION TITLE */}	
                  <div className="col-lg-6">
                    <div className="newsletter-txt">	
                      {/* Section ID */}	
                      <span className="section-id">Subscribe to Our Newsletter</span>
                      {/* Title */}	
                      <h4 className="h4-xl">Stay up to date with our news, ideas and updates</h4>	
                    </div>								
                  </div>
                  {/* NEWSLETTER FORM */}
                  <div className="col-lg-6">
                    <form className="newsletter-form">
                      <div className="input-group">
                        <input type="email" autoComplete="off" className="form-control" placeholder="Your email address" required id="s-email" />								
                        <span className="input-group-btn">
                          <button type="submit" className="btn btn-md btn-rose tra-rose-hover">Subscribe Now</button>
                        </span>										
                      </div>
                      {/* Newsletter Form Notification */}	
                      <label htmlFor="s-email" className="form-notification" />
                    </form>							
                  </div>	  {/* END NEWSLETTER FORM */}
                </div>	  {/* End row */}
              </div>    {/* End newsletter-holder */}
            </div>	   {/* End container */}	
          </section>	{/* END NEWSLETTER-1 */}
          {/* BLOG-1
			============================================= */}
          <section id="blog-1" className="wide-60 blog-section division">				
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-10 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Our Tips and Latest News</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque at dolor primis libero
                      tempus, blandit and cursus varius magna tempus a dolor
                    </p>
                  </div>	
                </div>
              </div>
              {/* BLOG POSTS */}
              <div className="row">
                {/* BLOG POST #1 */}
                <div id="b-post-1" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.4s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-1-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">NordEx News</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Tempor sapien donec gravida ipsum a porta justo vitae</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>12 min read</p>
                      </div>	
                    </div>
                  </div>
                </div>	{/* END  BLOG POST #1 */}
                {/* BLOG POST #2 */}
                <div id="b-post-2" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.6s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-2-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">Inspiration</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Aliquam augue impedit luctus neque purus an ipsum neque and dolor libero risus</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>8 min read</p>
                      </div>	
                    </div>	{/* END BLOG POST TEXT */}
                  </div>
                </div>	{/* END  BLOG POST #2 */}
                {/* BLOG POST #3 */}
                <div id="b-post-3" className="col-md-6 col-lg-4">
                  <div className="blog-post mb-40 wow fadeInUp" data-wow-delay="0.8s">	
                    {/* BLOG POST IMAGE */}
                    <div className="blog-post-img">
                      <img className="img-fluid" src="assets/images/blog/post-3-img.jpg" alt="blog-post-image" />
                    </div>
                    {/* BLOG POST TEXT */}
                    <div className="blog-post-txt">
                      {/* Post Tag */}
                      <p className="p-md post-tag">Tutorials</p>	
                      {/* Post Link */}
                      <h5 className="h5-sm">
                        <a href="single-post.html">Tempor sapien NordEx, donec gravida ipsum a porta justo</a>
                      </h5>
                      {/* Text */}
                      <p className="p-md">Aliqum mullam blandit vitae tempor sapien a donec lipsum gravida porta velna dolor vitae auctor
                        congue 
                      </p>
                      {/* Post Meta */}
                      <div className="post-meta">
                        <div className="post-author-avatar"><img src="assets/images/post-author-1.jpg" alt="author-avatar" /></div>
                        <p>22 min read</p>
                      </div>	
                    </div>	{/* END BLOG POST TEXT */}
                  </div>
                </div>	{/* END  BLOG POST #3 */}
              </div>	{/* END BLOG POSTS */}
            </div>	   {/* End container */}		
          </section>	{/* END BLOG-1 */}
          {/* DOWNLOAD-5
			============================================= */}
          <section id="download-5" className="bg_image bg_sblue_img pt-100 download-section division">
            <div className="container white-color">
              <div className="row">	
                {/* DOWNLOAD TXT */}	
                <div className="col-lg-10 offset-lg-1">	
                  <div className="download-5-txt text-center wow fadeInUp" data-wow-delay="0.6s">
                    {/* Title 	*/}	
                    <h2 className="h2-md">Lightning Fast. Super Powerful</h2>	
                    {/* Text */}	
                    <p className="p-xl">Aliquam a augue suscipit, luctus neque purus ipsum neque dolor primis libero tempus, blandit 
                      posuere and ligula varius magna porta
                    </p>
                    {/* STORE BADGES */}												
                    <div className="stores-badge mb-50">
                      {/* AppStore */}
                      <a href="#" className="store">
                        <img className="appstore" src="assets/images/appstore.png" alt="appstore-badge" />
                      </a>
                      {/* Google Play */}
                      <a href="#" className="store">
                        <img className="googleplay" src="assets/images/googleplay.png" alt="googleplay-badge" />
                      </a> 
                      {/* Aamazon Market 
									<a href="#" class="store">
										<img class="amazon" src="assets/images/amazon.png" alt="amazon-badge" />
									</a>  */}
                      {/* Mac AppStore 
									<a href="#" class="store">
										<img class="mac-appstore" src="assets/images/macstore.png" alt="macstore-badge" />
									</a> */} 
                      {/* Microsoft Store  
									<a href="#" class="store">
										<img class="microsoft" src="assets/images/microsoft.png" alt="microsoft-badge" />
									</a> */} 
                    </div>	{/* END STORE BADGES */}	
                  </div>
                  {/* Image */}	
                  <div className="download-5-img wow fadeInUp" data-wow-delay="0.8s">
                    <img className="img-fluid" src="assets/images/img-20.png" alt="download-image" />
                  </div>
                </div>
              </div>    {/* End row */}
            </div>	   {/* End container */}	
          </section>	{/* END  DOWNLOAD-5 */}
        
        </div>	{/* END PAGE CONTENT */}
   
        
            </div>
        )
    }
}
export default Home_5 