import React, {Component} from 'react';

class Terms extends React.Component {
    render() {
        return(
            <div>


        {/* PRELOADER SPINNER
		============================================= */}	
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
		============================================= */}	
        <div id="page" className="page">
          
          {/* TERMS & PRIVACY
			============================================= */}
          <section id="terms-page" className="bg_whitesmoke hero-offset-nav pb-70 terms-section division">
            <div className="container">
              <div className="row">
                {/* TERMS CONTENT */}
                <div className="col-lg-10 offset-lg-1">
                  {/* TERMS TITLE */}
                  <div className="terms-title text-center">
                    {/* Title */}
                    <h2 className="h2-md">Gizlilik Politikası &amp; Sözleşmesi</h2>
                    {/* Text */}
                    <p className="p-xl grey-color">Son Güncelleme Tarihi 16 Ekim 2024</p>
                  </div>	{/* END TERMS TITLE */}
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Text */}
                    <p className="p-lg">Giriş Workfray olarak, kullanıcılarımızın kişisel verilerinin gizliliğine ve güvenliğine büyük önem veriyoruz. Bu gizlilik sözleşmesi, Workfray hizmetlerini kullanırken toplanan verilerin nasıl işlendiğini, saklandığını ve korunduğunu açıklamaktadır. Gizliliğinizi koruma taahhüdümüz gereği, bu politikayı dikkatlice okuyarak verilerinizin nasıl kullanıldığını anlayabilirsiniz.   </p>
                    {/* Text */}
                    <p className="p-lg">Toplanan Veriler Workfray, aşağıdaki türde verileri toplayabilir:

Kişisel Bilgiler: Adınız, soyadınız, e-posta adresiniz, telefon numaranız, iş pozisyonunuz gibi doğrudan kimliğinizi tanımlayan bilgiler.
Teknik Veriler: IP adresi, tarayıcı bilgileri, cihaz türü, çerezler ve diğer teknik bilgiler.
Kullanım Verileri: Workfray platformunu kullanırken yaptığınız işlemler, erişim zamanları, kullanılan özellikler ve etkileşimler.
Konum Verileri: Uygulamanın belirli işlevleri için konum bilgilerinizi kullanabiliriz (örneğin, görev konumu takibi).  

Verilerin Kullanımı Toplanan veriler, şu amaçlarla kullanılabilir:

Hizmetlerin sağlanması ve optimize edilmesi
Müşteri desteği sağlanması
Kullanıcı deneyimini geliştirmek için analiz yapılması
Güvenlik ve dolandırıcılığa karşı koruma
İş ve pazarlama faaliyetlerini yürütmek

 </p>
                  </div>	{/* END TERMS BOX */}
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Çerezler</h4>
                    {/* Text */}
                    <p className="p-lg">Çerezler ve Takip Teknolojileri Workfray, kullanıcı deneyimini iyileştirmek ve platform kullanımını izlemek amacıyla çerezler ve benzeri takip teknolojileri kullanır. Çerezlerin kullanımını tarayıcı ayarlarınızdan yönetebilirsiniz, ancak çerezleri devre dışı bırakmak bazı hizmetlerin düzgün çalışmamasına neden olabilir.
                    </p>
                    {/* Text */}
                   
                    {/* List */}	
                  
                    {/* Text */}
                    <p className="p-lg">Verilerin Paylaşımı Kişisel verileriniz, aşağıdaki durumlarda üçüncü taraflarla paylaşılabilir:

Yasal zorunluluklar: Yasaların gerektirdiği durumlarda ve yetkili mercilere yanıt olarak.
Hizmet sağlayıcılar: İş ortaklarımız ve hizmet sağlayıcılarımızla iş birliği içinde olduğumuz süreçlerde.
İş transferi: Workfray’in tamamen veya kısmen devri durumunda kişisel veriler yeni iş sahipleriyle paylaşılabilir.
    </p>
                  </div>	{/* END TERMS BOX */}
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Veri Güvenliği</h4>
                    {/* Text */}  
                    <p className="p-lg">Veri Güvenliği Workfray, kişisel verilerinizi korumak için endüstri standardı güvenlik önlemleri kullanmaktadır. Verilerinize yetkisiz erişimi önlemek için güvenlik duvarları, şifreleme teknolojileri ve kimlik doğrulama süreçleri kullanılmaktadır.    </p>
                  </div>	{/* END TERMS BOX */}
                  {/* TERMS BOX #3 */}
                  <div className="terms-box">
                        <p className="p-lg">Veri Saklama Süresi Kişisel verileriniz, hizmetlerin sağlanması ve yasal zorunluluklar doğrultusunda gerektiği sürece saklanacaktır. Verilerin saklanma süresi sona erdiğinde, bu veriler güvenli bir şekilde imha edilir.</p>
                    {/* Text */}  
                    
                  </div>	{/* END TERMS BOX */}
             
               
                 
                 
                 
                  
                
                  {/* TERMS BOX */}
                  <div className="terms-box">
                    {/* Title */}
                    <h4 className="h4-sm">Kullanıcı Hakları</h4>
                    {/* Text */}
                    <p className="p-lg">Kullanıcı Hakları Kullanıcılar, aşağıdaki haklara sahiptir:

Verilere erişim talep etme
Yanlış veya eksik bilgilerin düzeltilmesi
Verilerin silinmesini talep etme
Verilerin işlenmesine itiraz etme
Değişiklikler Bu gizlilik sözleşmesi zaman zaman güncellenebilir. Değişiklikler, Workfray platformu üzerinden bildirilir ve kullanıcılar bu değişiklikleri kabul etmiş sayılır.

   </p>
                  
                   
                  
                  </div>	{/* END TERMS BOX */}
                </div>	{/* END TERMS CONTENT */}
              </div>     {/* End row */}		
            </div>	    {/* End container */}
            {/* GEOMETRIC OVERLAY */}	
            <div className="bg_fixed geometric_overlay" />
          </section>	 {/* END TERMS & PRIVACY */}
      
        </div>	{/* END PAGE CONTENT */}
     


            </div>
        )
    }
}
export default Terms 