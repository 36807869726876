import React, {Component} from 'react';

class Pricing extends React.Component {
    render() {
        return(
            <div>


            
        {/* PRELOADER SPINNER
    ============================================= */} 
        <div id="loader-wrapper">
          <div id="loading">
            <span className="cssload-loader"><span className="cssload-loader-inner" /></span>
          </div>
        </div>
        {/* PAGE CONTENT
    ============================================= */} 
        <div id="page" className="page">
          {/* HEADER
      ============================================= */}
        
          {/* PRICING-3
      ============================================= */}
          <section id="pricing-3" className="bg_whitesmoke hero-offset-nav pb-50 pricing-section division">
            <div className="container">
              {/* SECTION TITLE */} 
              <section id="pricing-2" className="pb-100 pricing-section division">
            <div className="container">
              {/* SECTION TITLE */}	
              <div className="row">	
                <div className="col-lg-12 offset-lg-1">
                  <div className="section-title text-center mb-70">		
                    {/* Title */}	
                    <h2 className="h2-md">Fiyatlandırmalarımız</h2>	
                    {/* Text */}	
                    <p className="p-xl">İhtiyaçlarınıza göre esnek ve adil fiyatlandırma seçenekleri. Workfray ile her bütçeye uygun çözümler sunuyoruz.
                    </p>
                  </div>	
                </div>
              </div>
              {/* PRICING TABLES */}
              <div className="row text-center">
                {/* FREE PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.4s">  
                    {/* Plan Price  */}
                    <div className="pricing-plan bg_white">
                      <h5 className="h5-md">1-10 Çalışan</h5>                  
                      <sup className="dark-color">₺</sup>               
                      <span className="dark-color">2.000</span>
                      <p className="dark-color">Aylık</p>
                      <p className="p-sm">7 Günlük Ücretsiz Deneme Süresi</p>     
                      <a href="#" className="btn btn-tra-grey skyblue-hover mb-15">Planı Seç</a>
                      {/* Pricing Trial Link */}  
                    </div>  
                    {/* Plan Description */}
                    <div className="pricing-description bg_white">
                      <ul className="features">
                        <li><p className="p-lg">Görev Takibi</p></li>
                        <li><p className="p-lg">Stok Takibi</p></li>
                        <li><p className="p-lg">Görev Konum Takibi</p></li>
                        <li><p className="p-lg">Kişisel Asistan</p></li>              
                        <li><p className="p-lg">Analizler</p></li> 
                        <li><p className="p-lg">Yönetici & Çalışan Uygulaması</p></li>

                      </ul>
                    </div>
                  </div>
                </div>  {/* END PFREE PLAN */}
                {/* MONTHLY PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.6s">  
                    {/* Plan Price  */}
                    <div className="pricing-plan bg-white">
                      <h5 className="h5-md">10-30 Çalışan</h5>  
                      <sup className="dark-color">₺</sup>                 
                      <span className="dark-color">6.000</span>
                      <p className="dark-color">Aylık</p>
                      <p className="p-sm">7 Günlük Ücretsiz Deneme Süresi</p>
                      {/* Pricing Table Button */}
                      <a href="#" className="btn btn-tra-grey skyblue-hover mb-15">Planı Seç</a>
                      {/* Pricing Trial Link */}  
                    </div>  
                    {/* Plan Description */}
                    <div className="pricing-description bg-white">
                      <ul className="features">
                      <li><p className="p-lg">Görev Takibi</p></li>
                        <li><p className="p-lg">Stok Takibi</p></li>
                        <li><p className="p-lg">Görev Konum Takibi</p></li>
                        <li><p className="p-lg">Kişisel Asistan</p></li>              
                        <li><p className="p-lg">Analizler</p></li> 
                        <li><p className="p-lg">Yönetici & Çalışan Uygulaması</p></li>

                      </ul>
                    </div>
                  </div>
                </div>  {/* END MONTHLY PLAN */}
                {/* ANNUAL PLAN */}
                <div className="col-md-4">
                  <div className="pricing-table mb-40 wow fadeInUp" data-wow-delay="0.8s">  
                    {/* Plan Price  */}
                    <div className="pricing-plan highlight crocus_gradient white-color">
                      <h5 className="h5-md">30-50 Çalışan</h5> 
                      <sup>₺</sup>              
                      <span>12.000</span>
                      <p>Aylık</p>
                      <p className="p-sm">7 Günlük Ücretsiz Deneme Süresi</p>
                      {/* Pricing Table Button */}
                      <a href="#" className="btn btn-tra-white white-hover mb-15">Planı Seç</a>
                      {/* Pricing Trial Link */}  
                    </div>  
                    {/* Plan Description */}
                    <div className="pricing-description bg-white">
                      <ul className="features">
                      <li><p className="p-lg">Görev Takibi</p></li>
                        <li><p className="p-lg">Stok Takibi</p></li>
                        <li><p className="p-lg">Görev Konum Takibi</p></li>
                        <li><p className="p-lg">Kişisel Asistan</p></li>              
                        <li><p className="p-lg">Analizler</p></li> 
                        <li><p className="p-lg">Yönetici & Çalışan Uygulaması</p></li>

                      </ul>
                    </div>
                  </div>
                </div>  {/* END ANNUAL PLAN */}
                
              </div>  {/* END PRICING TABLES */}
              <div className="row">
                <div className="col-md-12">
                  <div className="pricing-2-download-btn mt-20 text-center wow fadeInUp" data-wow-delay="0.8s">
                    <a href="#" className="btn btn-md btn-tra-grey crocus_gradient mb-10">7 Günlük Ücretsiz Deneme Süresi</a>
                    <span className="os-version">Şimdi Başlama Zamanı</span>
                  </div>
                </div>
              </div>	{/* END DOWNLOAD BUTTON */}	
            </div>	   {/* End container */}
          </section>	{/* END PRICING-2 */}
            </div>     {/* End container */}
            {/* GEOMETRIC OVERLAY */} 
            <div className="bg_fixed geometric_overlay" />
          </section>  {/* END PRICING-3*/}
          {/* ABOUT-2
      ============================================= */}
          <div id="about-2" className="bg_whitesmoke pb-80 about-section division">
            <div className="container">
              {/* TEXT BLOCK */}  
              <div className="about-2-txt pc-50">
                {/* SMALL TITLE */}
                <div className="row">
                  <div className="col-md-12">
                    <div className="txt-block text-center mb-40">
                      <h5 className="h5-xl">Zaman Ve Maddi Tasarruf Sağlayın</h5>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* LEFT COLUMN */}
                  <div className="col-md-6">
                    <div className="txt-block top-box">
                      {/* List */}  
                      <ul className="simple-list">
                        <li className="list-item">
                          <p className="p-lg">Tüm görevlerinizi organize edin ve takip edin: Görevleri ekip üyelerine atayın, önceliklendirin ve işlerin ilerlemesini anında izleyin. Her görev için son tarih belirleyin ve durum güncellemelerini takip edin.
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Stoklarınızı dijital ortamda takip edin: Malzeme eksikliklerini önceden fark edin ve sipariş sürecini kolayca yönetin. Depo envanterinizi anlık olarak izleyin ve iş süreçlerinize entegre edin.
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Sahadaki ekiplerinizle anında iletişim kurun: Gerçek zamanlı canlı bağlantılar sayesinde sorunları hızlıca çözün ve projelerinizdeki aksaklıkları minimize edin.
                          </p>
                        </li>
                      </ul> {/* End List */}  
                    </div>
                  </div>  {/* END LEFT COLUMN */}
                  {/* RIGHT COLUMN */}
                  <div className="col-md-6">
                    <div className="txt-block">
                      {/* List */}  
                      <ul className="simple-list">
                        <li className="list-item">
                          <p className="p-lg">Ekip ve departmanları yönetin: Her departman için görev dağılımı yapın, sorumluluk alanlarını belirleyin ve departman bazlı performans raporları oluşturun.
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">İş süreçlerinizi derinlemesine analiz edin: Projelerinizin her aşamasını detaylı analizlerle inceleyin. Verimliliği artırmak ve karar alma süreçlerini iyileştirmek için raporlar oluşturun.
                          </p>
                        </li>
                        <li className="list-item">
                          <p className="p-lg">Görevlerinizi harita üzerinden takip edin: Sahadaki görevlerinizi anlık olarak harita üzerinden izleyin, görev yerlerini belirleyin ve sahadaki durumu net bir şekilde görün.
                          </p>
                        </li>
                      </ul> {/* End List */}  
                    </div>
                  </div>  {/* END RIGHT COLUMN */}  
                </div>
              </div>  {/* END TEXT BLOCK */}  
            </div>     {/* End container */}  
          </div>  {/* END ABOUT-2 */}
          {/* STATISTIC-1
      ============================================= */}
      
          {/* FAQs-2
      ============================================= */}
     
          {/* DOWNLOAD-6
      ============================================= */}
       
        </div>  


            </div>
            

        )
    }
}
export default Pricing