import React, {component} from 'react';
import { Link } from "react-router-dom";

class Header extends React.Component {
    render(){
        return(
            <div>

<header id="header" className="header white-menu navbar-dark">

                <div className="header-wrapper">
				
				    <div className="wsmobileheader clearfix">	  	
				    	<span className="smllogo"><img
						  src={`${process.env.PUBLIC_URL}/assets/images/logo-01.png`}
						 alt="mobile-logo"/></span>
				    	<Link to="#" id="wsnavtoggle" className="wsanimated-arrow"><span></span></Link>	
				 	</div>

				  	<div className="wsmainfull menu clearfix">
	    				<div className="wsmainwp clearfix">
	    					<div className="desktoplogo"><Link to="#" className="logo-black"><img
							
							src={`${process.env.PUBLIC_URL}/assets/images/logo-01.png`}
							
							alt="header-logo"/></Link></div>
	    					<div className="desktoplogo"><Link to="#" className="logo-white"><img src="assets/images/logo-white.png" alt="header-logo"/></Link></div>

	      					<nav className="wsmenu clearfix">
	        					<ul className="wsmenu-list nav-rose-hover">

									  <li aria-haspopup="true"><Link to="/home_4">Anasayfa <span className="nl-simple"></span></Link>
						        		
						          	</li>



							    	<li className="nl-simple" aria-haspopup="true"><Link to="/about">Hakkımızda</Link></li>
						        	<li aria-haspopup="true"><Link to="pricing">Fiyatlandırma</Link>
						        		
						          	</li>	
								    <li className="nl-simple" aria-haspopup="true">
								    	<Link to="#" className="btn btn-green tra-grey-hover last-link">İletişim</Link>
								    </li> 

	        					</ul>
	        				</nav>
	    				</div>
	    			</div>	
				</div> 
			</header>	
            </div>
        )
    }
}
export default Header 